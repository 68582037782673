import React, { useEffect, useState } from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addCounterLineInCertificateById } from "../../store/actions";

const suppliesList = [
  { software: "Control y Auditoría", name: "CPM ( Cloud Print management)" },
  { software: "Control y Auditoría", name: "LPM ( Lexmark Print Management)" },
  { software: "Monitoreo", name: "CFM ( Cloud Fleet Management)" },
  { software: "Monitoreo", name: "Markvision Enterprise" },
];

const Item = ({
  id,
  addItemIntoListCertification,
  softwareInput,
  nameInput,
  removeItemIntoListCertificationSoftware,
  code,
}) => {
  const [idItem, setIdItem] = useState(id);

  const [isAdmin, setIsAdmin] = useState(false);
  const statusCertification = useSelector(
    (state) => state.TeamCertifications.statusCertification
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addCounterLineInCertificateById(code));
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "ROLE_ADMIN") {
      setIsAdmin(true);
    }
    console.log(statusCertification);
  }, []);

  // this variable will be used to create a supply
  const [software, setSoftware] = useState(softwareInput);
  const [name, setName] = useState(nameInput);

  useEffect(() => {
    setSoftware(softwareInput);
    setName(nameInput);
  }, [softwareInput, nameInput]);

  // this variable represent if the component is or not deleted
  const [isDeleted, setIsDeleted] = useState(false);

  // this will be used to send the product to the parent component
  // but this will be send only if the all inputs are completed
  useEffect(() => {
    if (software.trim() !== "" && name.trim() !== "") {
      addItemIntoListCertification({ id, software, name });
    }
  }, [software, name]);

  // this function will be used to rendered by software
  const renderedBySoftware = Object.values(suppliesList).map((item, index) => {
    return (
      <option
        key={index}
        value={item.software.toUpperCase()}
        label={item.software.toUpperCase() + " - " + item.name.toUpperCase()}
      />
    );
  });

  // this function will be used to rendered by name
  const renderedByName = Object.values(suppliesList).map((item, index) => {
    return (
      <option
        key={index}
        value={item.name.toUpperCase()}
        label={item.software.toUpperCase() + " - " + item.name.toUpperCase()}
      />
    );
  });

  // this function will be used to item the product by software,
  // once the software is searched we need to complete the other inputs with
  // the value has the element selected
  const selectBySoftware = (e) => {
    // first we need to search the product complete
    const element = suppliesList.find((item) => {
      return e.target.value === item.software.toUpperCase();
    });

    if (element) {
      setSoftware(element.software);
      setName(element.name);
    } else {
      setSoftware(e.target.value);
      setName("");
    }
  };

  // this function will be used to item the product by name,
  // once the name is searched we need to complete the other inputs with
  // the value has the element selected
  const selectByName = (e) => {
    // first we need to search the product complete
    const element = suppliesList.find((item) => {
      return e.target.value === item.name.toUpperCase();
    });

    if (element) {
      setSoftware(element.software);
      setName(element.name);
    } else {
      setSoftware("");
      setName(e.target.value);
    }
  };

  // this function will be used to delete the component
  const deleteComponentClicked = () => {
    setIsDeleted(true);
    removeItemIntoListCertificationSoftware(idItem);
  };

  return (
    <>
      {" "}
      {!isDeleted && (
        <div>
          <div className="row mb-1 mt-3">
            <div className="col-6">
              <p style={{ fontWeight: "bold" }}>DESCRIPCION</p>
            </div>
            <div className="col-6">
              <p style={{ fontWeight: "bold" }}>SOFTWARE</p>
            </div>
          </div>

          <div className="row">
            {isAdmin ? (
              <div className="col-6">
                <input
                  className="form-control"
                  list="datalistOptions2"
                  value={name}
                  onChange={(e) => selectByName(e)}
                  disabled
                />
                <datalist id="datalistOptions">{renderedBySoftware}</datalist>
              </div>
            ) : (
              <div className="col-6">
                <input
                  className="form-control"
                  list="datalistOptions2"
                  value={name}
                  onChange={(e) => selectByName(e)}
                  disabled={
                    statusCertification === "RECHAZADO" ||
                    statusCertification === ""
                      ? false
                      : true
                  }
                />

                <datalist id="datalistOptions">{renderedBySoftware}</datalist>
              </div>
            )}

            {isAdmin ? (
              <div className="col-6 d-flex">
                <input
                  className="form-control"
                  list="datalistOptions"
                  value={software}
                  onChange={(e) => selectBySoftware(e)}
                  disabled
                />
                <datalist id="datalistOptions2">{renderedByName}</datalist>
              </div>
            ) : (
              <div className="col-6 d-flex">
                <input
                  className="form-control"
                  list="datalistOptions"
                  value={software}
                  onChange={(e) => selectBySoftware(e)}
                  disabled={
                    statusCertification === "RECHAZADO" ||
                    statusCertification === ""
                      ? false
                      : true
                  }
                />
                <datalist id="datalistOptions2">{renderedByName}</datalist>

                {statusCertification === "RECHAZADO" && (
                  <button
                    className="btn btn-danger-lexmark"
                    style={{ marginLeft: 20 }}
                    onClick={deleteComponentClicked}
                  >
                    <BsFillXCircleFill />
                  </button>
                )}

                {statusCertification === "" && (
                  <button
                    className="btn btn-danger-lexmark"
                    style={{ marginLeft: 20 }}
                    onClick={deleteComponentClicked}
                  >
                    <BsFillXCircleFill />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Item;
