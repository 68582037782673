import React, { useState, useEffect } from "react"
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { addCounterLineInCertificateById } from "../../store/actions";


const Item = ({ id, addItemIntoListCertification, modelInput, descriptionInput, amountInput, removeItemIntoListCertification, code }) => {

    const [idItem, setIdItem] = useState(id)

    const [isAdmin, setIsAdmin] = useState(false);
    const statusCertification = useSelector(state => state.TeamCertifications.statusCertification)

    const dispatch = useDispatch()

    // this variable will be uses to create a technical certification
    const [model, setModel] = useState(modelInput)
    const [description, setDescription] = useState(descriptionInput)
    const [amount, setAmount] = useState(amountInput)

    useEffect(() => {
        setModel(modelInput)
        setDescription(descriptionInput)
        setAmount(amountInput)
    }, [modelInput, descriptionInput, descriptionInput])

    // this variable represent if the component is or not deleted
    const [isDeleted, setIsDeleted] = useState(false)

    useEffect(() => {
        dispatch(addCounterLineInCertificateById(code))
    }, [])

    // this will be used to send the product to the parent component
    // but this will be send only if the all inputs are completed
    useEffect(() => {
        if (model.trim() !== '' && description.trim() !== '' && amount.trim() !== '') {
            addItemIntoListCertification({ id, model, description, amount })
        }
    }, [id, model, description, amount])

    // this function will be used to delete the component
    const deleteComponentClicked = () => {
        setIsDeleted(true)
        removeItemIntoListCertification(idItem)
    }

    return (
        <>  {!isDeleted && (
            <div>
                <div className="row mb-1 mt-3">
                    <div className="col-4">
                        <p style={{ fontWeight: 'bold' }}>
                            MODELO
                        </p>
                    </div>
                    <div className="col-4">
                        <p style={{ fontWeight: 'bold' }}>
                            DESCRIPCION
                        </p>
                    </div>
                    <div className="col-4">
                        <p style={{ fontWeight: 'bold' }}>
                            CANTIDAD
                        </p>
                    </div>

                </div>

                <div className="row">

                    {isAdmin ? (
                        <div className="col-4">
                            <input
                                className="form-control"
                                type="text"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                disabled
                            />
                        </div>
                    ) : (

                        <div className="col-4">
                            <input
                                className="form-control"
                                type="text"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}

                            />
                        </div>
                    )}

                    {isAdmin ? (
                        <div className="col-4">
                            <input
                                className="form-control"
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled
                            />
                        </div>
                    ) : (

                        <div className="col-4">
                            <input
                                className="form-control"
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}
                            />
                        </div>
                    )}

                    {isAdmin ? (
                        <div className="col-4 d-flex">
                            <input
                                className="form-control"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    ) : (



                        <div className="col-4 d-flex">
                            <input
                                className="form-control"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}

                            />

                            {statusCertification === 'RECHAZADO' && (
                                <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                    deleteComponentClicked
                                }>

                                    < BsFillXCircleFill />

                                </button>
                            )}

                            {statusCertification === '' && (
                                <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                    deleteComponentClicked
                                }>

                                    < BsFillXCircleFill />

                                </button>
                            )}

                        </div>
                    )}

                </div>
            </div>
        )}
        </>
    )

}

export default Item;