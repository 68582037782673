import React, { Component, useState } from "react";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

// import the use-http
import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

import { Carousel } from "react-bootstrap";
import { useEffect } from "react";

const Login = ({ history, loginError, checkLogin }) => {
  // this variable will be used to login the user
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // this variable will be used to know if the user is logged in or not
  const [isLogged, setIsLogged] = useState(false);
  // this variable will be used to know if the user login is failed or not
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  // this variable will control the error message
  const [errorMessage, setErrorMessage] = useState("");

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // this variable will be used to redirect to another page
  //let history = useHistory()

  // create a function to get all data from jwt token
  const getDataFromToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    request(
      {
        url: `/noauth/banner/Login`,
        method: "GET",
        headers: myHeaders,
      },
      handleGetBannersDataResponse
    );
  };

  useEffect(() => {
    getBanners();
  }, []);

  const handleGetBannersDataResponse = (response) => {
    if (response.status === 401) {
      // show a error message here
    } else {
      try {
        console.log(response);
        setBanners(
          response.map((item) => {
            return {
              src: `${process.env.REACT_APP_S3_URL}${item.path}`,
              alt: item.title,
            };
          })
        );

        //setTipoCanal(response.tipo_canal)
        //setAcuerdoComercialDocumento(response.acuerdo_comercial)

        //seRucDocumento(selected.ruc_documento)
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateOpportunitiesDataResponse = (response) => {
    if (response?.status === 401) {
      // show a error message here
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }
      toast(response.message, { type: "error", autoClose: 10000 });
    } else {
      for (let x = 0; x < response.length; x++) {
        toast(response[x], { type: "error", autoClose: 100000 });
      }
    }
  };

  const validateOpportunities = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/oportunidad/notify`,
          method: "GET",
          headers: myHeaders,
        },
        validateOpportunitiesDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginDataResponse = (data) => {
    if (data.status === 401) {
      toast(data.message, { type: "error", autoClose: 10000 });

      if (data.errorCode === 15) {
        // In this case the user is not active
        // this user need to create a new password
        history.push("/change-password-to-available/" + username);
      } else {
        // set the error message
        setErrorMessage(data.message);
        setIsLoginFailed(true);
      }
    } else {
      // set the user is logged in
      setIsLogged(true);
      // set the user token
      localStorage.setItem("token", data.token);
      // get the role of the user
      const userData = getDataFromToken(data.token);
      // set the user role
      localStorage.setItem("role", userData["cognito:groups"][0]);
      localStorage.setItem("email ", username);

      //history.push('/dashboard');

      validateOpportunities();

      checkLogin(
        {
          password: "123456",
          username: "admin@themesdesign.in",
        },
        history
      );
    }
  };

  const handleSubmit = (event, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      request(
        {
          url: "/auth/login",
          method: "POST",
          headers: myHeaders,
          body: {
            username: username.trim(),
            password: password.trim(),
          },
        },
        handleLoginDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div style={{ overflow: "hidden" }}>
        <Container fluid className="p-0">
          <Row
            className="g-0 d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Col lg={4} style={{ marginBottom: 0 }}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center mt-4">
                          <div>
                            <Link to="/" className="">
                              <img
                                src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-logo-2x.svg"
                                alt=""
                                height="20"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-logo-2x.svg"
                                alt=""
                                height="20"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h5 className="font-size-16 mt-4">
                            Bienvenido al Portal Lexmark Ecuador
                          </h5>
                          <p className="text-muted font-size-13">
                            Inicia sesión para continuar a Lexmark Ecuador.
                          </p>
                        </div>

                        <div className="p-2 mt-3">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <div className={`${loading && "is-loading"}`}>
                              {isLoginFailed && isLoginFailed ? (
                                <Alert color="danger">{errorMessage}</Alert>
                              ) : null}
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i
                                className="ri-user-2-line auti-custom-input-icon color-primary-lexmark"
                                style={{ color: "#008945" }}
                              ></i>
                              <Label htmlFor="username">Email</Label>
                              <AvField
                                name="username"
                                value={username}
                                type="text"
                                className="form-control"
                                id="username"
                                validate={{ email: true, required: true }}
                                placeholder="Enter username"
                                onChange={(e) => setUsername(e.target.value)}
                                errorMessage="Por favor ingrese un email válido"
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i
                                className="ri-lock-2-line auti-custom-input-icon"
                                style={{ color: "#008945" }}
                              ></i>
                              <Label htmlFor="userpassword">Contraseña</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                validate={{ required: true }}
                                errorMessage="Por favor ingrese su contraseña"
                              />
                            </div>
                            {/* 
                                                            <div className="form-check">
                                                                <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                                <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                                            </div>
                                                            */}

                            <div className="mt-4 text-center">
                              <button
                                className="w-md waves-effect waves-light btn btn-primary-lexmark"
                                type="submit"
                              >
                                Iniciar Sesión
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1"></i> ¿Olvidaste
                                tu contraseña?
                              </Link>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-2 text-center">
                          {/* 
                                                        <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p>
                                                        */}
                          <div className="container d-flex flex-column justify-content-center align-items-center">
                            <ul className="legal-menu-brand">
                              <li className="legal-menu-brand-logo">
                                <img
                                  src="//media.lexmark.com/www/lxkfef/themes/lxk/v1.0.12/images/logos/lxk-symbol-2x.svg"
                                  alt="Símbolo de Lexmark"
                                />
                              </li>
                              <li>
                                <ul className="legal-menu-brand-copyright">
                                  <li>Lexmark International, Inc.</li>
                                  <li>&copy;2022 All rights reserved.</li>
                                </ul>
                              </li>
                            </ul>
                            <ul className="legal-menu-notice mt-2">
                              <li>
                                <a href="/es_xl/privacy-policy.html">
                                  Política de privacidad
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              lg={8}
              style={{ height: "100%", marginBottom: 0, paddingBottom: 0 }}
            >
              <Carousel>
                {/* 
                                <Carousel.Item interval={30000} >
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/1.jpg"
                                        alt="Second slide"
                                        style={{ width: '100% !important' }}

                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>
                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/2.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/3.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                */}{" "}
                {banners.map((item, key) => (
                  <Carousel.Item interval={5000} key={key}>
                    <img
                      className="d-block w-100 carousel-login"
                      src={item.src}
                      alt={item.alt}
                    />
                  </Carousel.Item>
                ))}
                {/* <Carousel.Item interval={30000}>

                                    <img
                                        className="d-block w-100 authentication-bg"
                                        src="./assets/lexmark_principal/4.jpg"
                                        alt="Second slide"
                                    />

                                </Carousel.Item> */}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
