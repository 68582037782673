// Funciones de Utilidad de Tiempo
export function formatDateTime(createdDate) {
  let date;

  if (typeof createdDate === "string") {
    date = new Date(createdDate); // Convertir la cadena ISO en objeto Date
  } else if (typeof createdDate === "object" && createdDate.seconds) {
    date = new Date(createdDate.seconds * 1000); // Convertir segundos a milisegundos
  } else {
    return "Fecha inválida"; // Manejo de error si el formato es desconocido
  }

  const formattedDate = date.toLocaleDateString("es-ES"); // Formatear en dd/mm/aaaa
  return formattedDate;
}

export function formatDateHour(createdDate) {
  let date;

  if (typeof createdDate === "string") {
    date = new Date(createdDate);
  } else if (typeof createdDate === "object" && createdDate.seconds) {
    date = new Date(createdDate.seconds * 1000);
  } else {
    return "Hora inválida";
  }

  const formattedTimeHour = date.toLocaleTimeString("es-ES", { hour12: true }); // Formato 12h con AM/PM
  return formattedTimeHour;
}
