import React, { useState } from "react"
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs"

const suppliesList = [
    { np: "55B4000", model: "MS331dn", product: "3K -Standard Yield Toner", yield: "3,000" },
    { np: "55B4H00", model: "MS331dn", product: "15K -High Yield Toner", yield: "15,000" },
    { np: "55B0ZA0", model: "MS331dn", product: "40K -Black PC ", yield: "40,000" },
    { np: "2371703", model: "MS331dn", product: "MS331 1yr OSR  NBD", yield: "" },
    { np: "2371704", model: "MS331dn", product: "MS331 2yr OSR  NBD", yield: "" },
    { np: "2371705", model: "MS331dn", product: "MS331 3yr OSR  NBD", yield: "" },
]

const ListSupplies = ({ id = 0, addSupplyIntoListSupplies, nps, models, descriptions, amounts }) => {

    // this variable will be used to create a supply
    const [np, setNp] = useState(nps)
    const [model, setModel] = useState(models)
    const [description, setDescription] = useState(descriptions)
    const [amount, setAmount] = useState(amounts)

    // this variable represent if the component is or not deleted
    const [isDeleted, setIsDeleted] = useState(false)
    // this variable control if the component was clicked 
    const [isClicked, setIsClicked] = useState(false)

    // this function will be used to rendered by np
    const renderedByNP = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.np.toUpperCase()}
                label={item.np.toUpperCase() + " - " + item.model.toUpperCase() + " - " + item.product}
            />
        )
    })

    // this function will be used to rendered by model
    const renderedByProduct = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.product.toUpperCase()}
                label={item.np.toUpperCase() + " - " + item.model.toUpperCase() + " - " + item.product}
            />
        )
    })

    // this function will be used to rendered by model
    const renderedByModel = Object.values(suppliesList).map((item, index) => {
        return (
            <option
                key={index}
                value={item.model.toUpperCase()}
                label={item.np.toUpperCase() + " - " + item.model.toUpperCase() + " - " + item.product}
            />
        )
    })

    // this function will be used to get the product by description product,
    // once the product is searched we need to complete the other inputs with 
    // the value has the element selected
    const selectByProductDescription = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.product.toUpperCase();
        })

        if (element) {
            setNp(element.np);
            setModel(element.model)
            setDescription(element.product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp('');
            setModel('')
            setDescription(e.target.value)
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    // this function will be used to get the product by np,
    // once the product is searched we need to complete the other inputs with 
    // the value has the element selected
    const selectByNp = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.np.toUpperCase();
        })

        if (element) {
            setNp(element.np);
            setModel(element.model)
            setDescription(element.product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp(e.target.value);
            setModel('')
            setDescription('')
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    const selectByModel = (e) => {

        // first we need to search the product complete
        const element = suppliesList.find(item => {
            return e.target.value === item.model.toUpperCase();
        })

        if (element) {
            setNp(element.np);
            setModel(element.model)
            setDescription(element.product)

            if (amount.trim() === '') {
                setIsClicked(true)
            } else {
                setIsClicked(false)
            }

        } else {
            setNp();
            setModel(e.target.value)
            setDescription('')
            // put red if the amount is null
            setIsClicked(true)
        }
    }

    // change the amount value
    const changeTheAmountValue = (e) => {
        const value = e.target.value
        if (value.trim().length < 0 || value === '') {
            setIsClicked(true)
        } else {
            setIsClicked(false)
        }
        setAmount(value)
    }

    // this function will be used to delete the component
    const deleteComponentClicked = () => {
        setIsDeleted(true)
    }

    // this function will be used to add the element to list
    const addComponentClicked = () => {
        if (model.length > 0 && amount.length > 0) {

            addSupplyIntoListSupplies(
                {
                    np, model, description, amount
                }
            )
        }
    }

    return (
        <>  {!isDeleted && (
            <div>
                <div className="row mb-1 mt-3">
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            NUMERO DE PARTE
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            MODELO
                        </p>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: 'bold' }}>
                            DESCRIPCIÓN
                        </p>
                    </div>
                    <div className="col-2">
                        <p style={{ fontWeight: 'bold' }}>
                            CANTIDAD
                        </p>
                    </div>
                </div>

                <div className="row">

                    <div className="col-3">
                        <input
                            disabled
                            className="form-control"
                            list="datalistOptions"
                            value={np}
                            onChange={(e) => selectByNp(e)}
                        />
                        <datalist id="datalistOptions">
                            {renderedByNP}
                        </datalist>
                    </div>

                    <div className="col-3">
                        <input
                            disabled
                            className="form-control"
                            list="datalistOptions3"
                            value={model.toUpperCase()}
                            onChange={(e) => selectByModel(e)}
                        />
                        <datalist id="datalistOptions3">
                            {renderedByModel}
                        </datalist>
                    </div>

                    <div className="col-3">
                        <input
                            disabled
                            className="form-control"
                            list="datalistOptions2"
                            value={description}
                            onChange={(e) => selectByProductDescription(e)}
                        />
                        <datalist id="datalistOptions2">
                            {renderedByProduct}
                        </datalist>
                    </div>

                    <div className="col-3 d-flex">
                        <input
                            disabled
                            className="form-control"
                            type="number"
                            value={amount}
                            onChange={(e) => changeTheAmountValue(e)}
                            style={{
                                borderColor: `${isClicked ? 'red' : ''}`
                            }}
                        />
                        {/** 
                        <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                            deleteComponentClicked
                        }>
                            < BsFillXCircleFill />
                        </button>
                        */}
                    </div>

                </div>
            </div>
        )}
        </>
    )

}

export default ListSupplies;