import React, { Component, useState, useEffect } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

import { Modal } from 'react-bootstrap';

// import the redux
import { useSelector, useDispatch } from 'react-redux'

import {
    BsFillCursorFill,
    BsBarChartSteps,
    BsPlusCircleFill,
    BsFillCalendarCheckFill,
    BsFillPersonCheckFill,
    BsFillRecord2Fill,
    BsReverseLayoutTextSidebarReverse,
    BsFillStopCircleFill,
    BsFillPencilFill,
    BsFillVinylFill,
    BsFillPatchCheckFill,
    BsFillXCircleFill,
} from "react-icons/bs";
import { addAllCertificationFromRequest, addStatusAdmin, addStatusCertifications, changeStatus, deleteAllCertificateSelected, updateCertificationDataJson, updateDataJson } from "../../store/actions";

// import the components bootstrap
import { Button as B, Modal as M } from 'react-bootstrap';

// import the component public sector
import PublicSector from "./PublicSector";

import PublicSectorAdmin from './AdminViews/PublicSectorAdmin';

// import the component private sector
import PrivateSector from "./PrivateSector";

import PrivateSectorAdmin from './AdminViews/PrivateSectorAdmin';

// import the supply component
import Supply from "./Supply";

// import the teams component
import Teams from "./Teams";

// import the special component 
import Specials from "./Specials";

// import the supply part component
import ListSupplies from './ListSupplies';

// import the select sector component 
import SelectSector from "./SelectSector";

import SelectSectorAdmin from "./AdminViews/SelectSectorAdmin";

import SelectSectorChannel from "./SelectSectorChannel"

import useHttp from "../../hook/use-http";

// import the style of the component
import './style.css'
import { toast } from "react-toastify";
import TeamsAdmin from "./AdminViews/TeamsAdmin";

/* This array represent different provider */
const providers = [
    { id: 1, name: "SIGLO 21" },
    { id: 2, name: "TECNOMEGA" },
    { id: 3, name: "MEGAMICRO" },
];

/* This array represent the list of type of channel */

const typesChannel = [
    { id: 1, name: "TRADICIONAL" },
    { id: 2, name: "CONNECT" },
]


// this variable will be used to control the type of the request
// this value will get in the param
let typeSolicitation = 'EQUIPOS'

const TeamCertificate = ({ match, history }) => {

    // this variable will be used to control the type of the request
    // this function will be used to request the server to login the user
    const { loading, error, request: requestUrl } = useHttp();

    const [codeRequest, setCodeRequest] = useState(match.params.code);


    // this variable will be control the amount of component supplies list
    const [listsComponent, setListsComponent] = useState();
    // this variable will be control the id of the component supplies list
    const [amountListSuppliesId, setAmountListSuppliesId] = useState(0)

    // this variable will be used to control which view private or public
    const [isPublic, setIsPublic] = useState(true)
    const handleChangeView = (e) => setIsPublic(e)


    // this variable represent the date of the request
    const [dateRequest, setDateRequest] = useState('')

    // get the data with redux dispatch
    const dispatch = useDispatch()
    const dataJson = useSelector(state => state.CertificationJsonConsume.data)

    useEffect(() => {
        dispatch(deleteAllCertificateSelected())
    }, [])


    /// get the list of the certification selected
    const certificationSelectedWithInputs = useSelector(state => state.TeamCertifications.certifications)
    const softwareItemsSelected = useSelector(state => state.TeamCertifications.software)

    // this variable will be used to get all information of the list teams certification 
    const listTeamsCertification = useSelector(state => state.TeamCertifications.certifications)

    // this variable will be used to set the channel selected
    const [request, setRequest] = useState([])

    // this variable will be used to get information of the channel
    const profile = useSelector(state => state.profileReducer.profile)

    /*********************************************************************** */
    /*********************************************************************** */
    /**  DECLARE THE VARIABLE USED TO CONTROL THE VIEW OF THE USER ***********/

    const [isAdmin, setIsAdmin] = useState(false)
    const [reasonRejection, setReasonRejection] = useState('')

    /*********************************************************************** */
    /*********************************************************************** */

    // this variable will control the if the admin can change the channel or no
    const [disabled, setDisabled] = useState(true)

    // this variable will be used to save the certification selected
    const [certifications, setCertifications] = useState([])

    // this will be used to control de component modal  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /***************************************************************/
    /***************************************************************/
    /**  declare state variables to approve or reject a request  ***/
    /***************************************************************/
    /***************************************************************/

    // this variable represent the comment if the request is reject
    const [observaciones, setObservaciones] = useState('')

    // this variable represent the status of the request
    const [statusRequest, setStatusRequest] = useState(null)

    // this variable represent the array of the supplies list part number
    const [partListSupplies, setPartListSupplies] = useState([]);
    // this function will be used to save the data of the process

    ///////////////////////////////////////////////////////////////////////////////////////////////
    const [dataProcess, setDataProcess] = useState({})

    const handleChangeDataProcess = (e) => {
        setDataProcess(e)
        console.log(e)
    }

    //////////////////////////////////////////////////////////////////////////////////////////////

    // this function will be used to process the request of getChannelProfile
    const getChannelProfileDataRequest = (e) => {

        if (!e.status) {
            // set the header of the request
            // add a extra field to the header
            setRequest(prov => {
                return {
                    ...prov,
                    razon_social: e.razonSocial,
                    ruc: e.ruc,
                    mayorista: e.mayorista,
                    tipoCanal: e.tipo,
                }
            })
        }
    }
    // this function will be used to get the profile of the channel
    const getChannelProfileAdmin = (ruc) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/channel/list/ruc/` + ruc,
                method: 'GET',
                headers: myHeaders,
            }, getChannelProfileDataRequest)

        } catch (err) {
            console.log(err)
        }

    }

    // this function will be used to process the request of getDataOfCodeRequest
    const getDataOfCodeRequestDataResponse = (response) => {
        if (!response.status) {
            // set information of the request 
            setRequest(prov => {
                return {
                    ...prov,
                    estado: response.estado,
                    mayorista: response.mayorista,
                    RUC: response.ruc,
                    estado: response.estado,
                    requerimiento: response.requerimentoEspecifico,
                    suministros: response.suministros,
                    sector: response.sector,
                    dataProcess: response.datosSectorPrivado || response.datosSectorPublico,
                    certificados: response.certificados,
                    motivoRechazo: response.motivoRechazo
                }
            })
            let d = new Date(response.createdDate)
            let dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');

            setDateRequest(dformat)

            dispatch(addAllCertificationFromRequest(response.equipos));
            dispatch(addStatusAdmin(isAdmin));
            dispatch(addStatusCertifications(response.estado));

            // In the case i will change the sector of the request
            if (response.sector === "PRIVADO") {
                setIsPublic(false)
            } else {
                setIsPublic(true)
            }

            getChannelProfileAdmin(response.ruc)

        }
    }

    // this function will be get the data of the code of the request
    const getDataOfCodeRequest = (code) => {

        try {
            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: `/auth/solicitud/list/` + code,
                method: 'GET',
                headers: myHeaders,
            }, getDataOfCodeRequestDataResponse)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // this variable will be used to get the id of the channel
        const { code } = match.params
        console.log(match.params)
        if (code) {
            getDataOfCodeRequest(code);
            setCodeRequest(code)
        } else {
            dispatch(addAllCertificationFromRequest([]));
            dispatch(addStatusAdmin(false));
            dispatch(addStatusCertifications(''));
        }


        // here i need to calculate the time of the request
        let d = new Date
        let dformat = [d.getMonth() + 1,
        d.getDate(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');

        setDateRequest(dformat)

        const role = localStorage.getItem('role')

        if (role == 'ROLE_CHANNEL') {
            setIsAdmin(false)
        } else {
            setIsAdmin(true)
        }

    }, [])

    // this function will be used when the request is reject
    const onHandleRejectOrApproveRequest = (status) => {

        const { id = 0 } = match.params;
        const observation = observaciones

        dispatch(updateCertificationDataJson(
            id,
            status,
            observation
        ), {
            type: 'UPDATE_DATA_JSON'
        })

        // close the modal
        handleClose()

        // redirect to list requests
        history.push('/approval-request-list')
    }

    // this function will be used to add a new part supply into list supplies
    const addSupplyIntoListSupplies = (supply) => {
        setPartListSupplies((prov) => {
            return [
                ...prov,
                supply
            ]
        })
    }

    // this function will be used to add certification into list teams 
    const addCertificationIntoListTeams = (certification) => {
        console.log(certification)
        setCertifications(prov => {
            return [...prov, certification]
        })
    }

    // this function will be used to remove certification into list teams
    const removeCertificationIntoListTeams = (certification) => {
        const index = certifications.indexOf(certification)
        setCertifications(prov => {
            return prov.filter((item, i) => i !== index)
        });
    }

    // rendered the certification selected
    const renderCertificationTeams = Object.values(certifications).map((item, index) => {
        return (
            <div key={index + 100}>
                {item.name_component}
            </div>
        )
    });

    const addNewComponentSupply = () => {
        const id = amountListSuppliesId + "_supply_id";
        setListsComponent((prov) => (
            <div id={id}>
                < ListSupplies
                    addSupplyIntoListSupplies={addSupplyIntoListSupplies}
                    id={id}
                />
                {prov}
            </div>
        ));
        setAmountListSuppliesId(prov => {
            return prov + 1;
        })
    }

    const generateElementErrorInInput = (name) => {
        const element = document.getElementById(name);
        element.classList.remove('is-untouched');
        element.classList.add('is-touched');
        element.classList.add('is-invalid');
    }

    const validateDataProcessSectorPrivate = () => {
        let isValid = true;

        if (document.getElementById('nombre_empresa').value.trim().length === 0) {
            generateElementErrorInInput('nombre_empresa');
            toast('Por favor ingresar el nombre de la empresa ', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('dirigido').value.trim().length === 0) {
            generateElementErrorInInput('dirigido');
            toast('Por favor ingresar a quien va dirigido ', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('cargo').value.trim().length === 0) {
            generateElementErrorInInput('cargo');
            toast('Por favor ingresar el cargo', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        return isValid;

    }

    const validateDataProcessSectorPublic = () => {
        let isValid = true;

        if (document.getElementById('entidad_contratante').value.trim().length === 0) {
            generateElementErrorInInput('entidad_contratante');
            toast('Por favor ingresar la entidad contrante', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('email_encargado').value.trim().length === 0) {
            generateElementErrorInInput('email_encargado');
            toast('Por favor ingresar el email del encargado', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('objecto_proceso').value.trim().length === 0) {
            generateElementErrorInInput('objecto_proceso');
            toast('Por favor ingresar el objeto del proceso', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('codigo_proceso').value.trim().length === 0) {
            generateElementErrorInInput('codigo_proceso');
            toast('Por favor ingresar el código del proceso', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('fecha_limite_preguntas').value.trim().length === 0) {
            generateElementErrorInInput('fecha_limite_preguntas');
            toast('Por favor ingresar la fecha limite de preguntas', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('fecha_limite_respuesta').value.trim().length === 0) {
            generateElementErrorInInput('fecha_limite_respuesta');
            toast('Por favor ingresar la fecha limite de respuesta', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('fecha_limite_entrega_oferta').value.trim().length === 0) {
            generateElementErrorInInput('fecha_limite_entrega_oferta');
            toast('Por favor ingresar la fecha limite de entrega de oferta', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('fecha_limite_calificacion').value.trim().length === 0) {
            generateElementErrorInInput('fecha_limite_calificacion');
            toast('Por favor ingresar la fecha limite de calificación', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('fecha_inicio_puja').value.trim().length === 0) {
            generateElementErrorInInput('fecha_inicio_puja');
            toast('Por favor ingresar la fecha de inicio de puja', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        if (document.getElementById('plazo_entrega').value.trim().length === 0) {
            generateElementErrorInInput('plazo_entrega');
            toast('Por favor ingresar el plazo de entrega', { type: 'error', autoClose: 10000 });
            isValid = false;
        }

        return isValid;
    }

    const validateDataProcess = () => {
        if (isPublic) {
            return validateDataProcessSectorPublic()
        } else {
            return validateDataProcessSectorPrivate()
        }
    }

    const validateCertificatedSelected = (certs) => {
        let isValid = true;
        if (certs.length === 0) {
            toast('Por favor seleccionar al menos un certificado', { type: 'error', autoClose: 10000 });
            isValid = false;
        }
        return isValid;
    }

    const sendRequestTeamsDataResponse = (response) => {
        if (response.status) {
            // show a error message here
            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            history.push('/request-sended')

        }
    }

    const sendRequestTeams = () => {


        console.log(certificationSelectedWithInputs)
        console.log(softwareItemsSelected)
        console.log(listTeamsCertification)
        console.log(certifications)
        console.log(dataProcess)

        let equipos = []

        equipos = Object.values(certificationSelectedWithInputs).map(cert => {

            let setItems = []

            if (cert.id === 'CER_EQU_002') { // DISTRIBUIDOR AUTIRIZADO DE SOFTWARE

                setItems = Object.values(cert.items).map(item => {
                    return {

                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": item.name,
                        "cantidad": "",
                        "modelo": [
                            item.name
                        ]
                    }
                })

            } else if (cert.id === 'CER_EQU_013') { // DISTRIBUIDOR AUTIRIZADO DE SOFTWARE

                setItems = Object.values(cert.items).map(item => {
                    return {

                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": item.name,
                        "cantidad": "",
                        "modelo": [
                            item.name
                        ]
                    }
                })

            } else if (cert.id === 'CER_EQU_009') { // CERTIFICADO TECNICOS SOFTWARE

                let setSoftware = []
                setSoftware = Object.values(cert.softwares).map(software => {
                    return software.name;
                })

                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": item.nombres,
                        "nom_tecnico": item.cedula + ' ' + item.apellidos,
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": "",
                        "cantidad": '',
                        "modelo": setSoftware
                    }
                })

            } else if (cert.id === 'CER_EQU_012') {

                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": item.ciudad,
                        "direccion": item.direccion,
                        "partno": "",
                        "descripcion": "",
                        "cantidad": '',
                        "modelo": []
                    }
                })

            } else if (cert.id === 'CER_EQU_008') {
                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": item.nombres,
                        "nom_tecnico": item.cedula + ' ' + item.apellidos,
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": "",
                        "cantidad": "",
                        "modelo": []
                    }
                })
            } else {
                // GARANTIA TECNICA EQUIPOS NUEVOS - "CER_EQU_003"
                // CERTIFICADO TECNICO EQUIPOS - "CER_EQU_008"
                // CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES - "CER_EQU_004"
                // CERTIFICADO DE EQUIPOS NUEVOS - "CER_EQU_005"
                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": item.model,
                        "descripcion": item.description,
                        "cantidad": item.amount,
                        "modelo": [item.model]
                    }
                })
            }

            return {
                "codigo": cert.id,
                "info_cert": setItems
            }
        })

        let allCertificationCodeInsideList = []
        allCertificationCodeInsideList = Object.values(certifications).map(cert => {
            const { code } = cert
            return code
        })

        console.log(equipos)
        console.log(allCertificationCodeInsideList)

        equipos = equipos.filter(item => {
            return allCertificationCodeInsideList.includes(item.codigo)
        })

        console.log(equipos)

        try {

            if (validateDataProcess() && validateCertificatedSelected(allCertificationCodeInsideList)) {

                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

                const sector = isPublic ? 'PUBLICO' : 'PRIVADO'

                if (isPublic) {

                    requestUrl({
                        // i need to get the url of the request
                        url: '/auth/solicitud/register/equipo',
                        method: 'POST',
                        headers: myHeaders,
                        body: {
                            "ruc": profile.ruc,
                            "descripcion": profile.razonSocial,
                            "tipo": "EQUIPO",
                            "mayorista": profile.mayorista,
                            "sector": sector,
                            "datosSectorPublico": dataProcess,
                            "certificados": allCertificationCodeInsideList,
                            "equipos": equipos,
                            "observaciones": "",
                        }
                    }, sendRequestTeamsDataResponse)

                } else {
                    requestUrl({
                        // i need to get the url of the request
                        url: '/auth/solicitud/register/equipo',
                        method: 'POST',
                        headers: myHeaders,
                        body: {
                            "ruc": profile.ruc,
                            "descripcion": profile.razonSocial,
                            "tipo": "EQUIPO",
                            "mayorista": profile.mayorista,
                            "sector": sector,
                            "datosSectorPrivado": dataProcess,
                            "certificados": allCertificationCodeInsideList,
                            "equipos": equipos,
                            "observaciones": "",
                        }
                    }, sendRequestTeamsDataResponse)
                }
            }


        } catch (er) {
            console.log(er)
        }


    }


    const sendEdithRequestTeamsDataResponse = (response) => {
        if (response.status) {
            // show a error message here
            if (response.errorCode === 12) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('email')
                localStorage.removeItem('role')
                history.push('/login')
            }

            toast(response.message, { type: 'error', autoClose: 10000 });
        } else {
            history.push('/request-sended')

        }
    }

    const sendEdithRequestTeams = () => {
        //console.log(certificationSelectedWithInputs)
        //console.log(softwareItemsSelected)
        //console.log(listTeamsCertification)
        //console.log(certifications)
        //console.log(dataProcess)

        let equipos = []

        equipos = Object.values(certificationSelectedWithInputs).map(cert => {

            let setItems = []

            if (cert.id === 'CER_EQU_002') { // DISTRIBUIDOR AUTIRIZADO DE SOFTWARE

                setItems = Object.values(cert.items).map(item => {
                    return {

                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": item?.name,
                        "cantidad": "",
                        "modelo": [
                            item?.name
                        ]
                    }
                })

            } else if (cert.id === 'CER_EQU_013') { // DISTRIBUIDOR AUTIRIZADO DE SOFTWARE

                setItems = Object.values(cert.items).map(item => {
                    return {

                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": item?.name,
                        "cantidad": "",
                        "modelo": [
                            item?.name
                        ]
                    }
                })

            } else if (cert.id === 'CER_EQU_009') { // CERTIFICADO TECNICOS SOFTWARE

                let setSoftware = []
                setSoftware = Object.values(cert.softwares).map(software => {
                    return software.name;
                })

                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": item?.nombres,
                        "nom_tecnico": item?.cedula + ' ' + item?.apellidos,
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": "",
                        "cantidad": '',
                        "modelo": setSoftware
                    }

                })

            } else if (cert.id === 'CER_EQU_012') {

                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": item?.ciudad,
                        "direccion": item?.direccion,
                        "partno": "",
                        "descripcion": "",
                        "cantidad": '',
                        "modelo": []
                    }
                })

            } else if (cert.id === 'CER_EQU_008') {
                setItems = Object.values(cert.items).map(item => {
                    return {
                        "ci_tecnico": item?.nombres,
                        "nom_tecnico": item?.cedula + ' ' + item?.apellidos,
                        "ciudad": "",
                        "direccion": "",
                        "partno": "",
                        "descripcion": "",
                        "cantidad": "",
                        "modelo": []
                    }
                })
            } else {
                // GARANTIA TECNICA EQUIPOS NUEVOS - "CER_EQU_003"
                // CERTIFICADO TECNICO EQUIPOS - "CER_EQU_008"
                // CERTIFICADO EQUIPOS EN OPTIMAS CONDICIONES - "CER_EQU_004"
                // CERTIFICADO DE EQUIPOS NUEVOS - "CER_EQU_005"
                setItems = Object.values(cert?.items).map(item => {

                    return {
                        "ci_tecnico": "",
                        "nom_tecnico": "",
                        "ciudad": "",
                        "direccion": "",
                        "partno": item?.model,
                        "descripcion": item?.description,
                        "cantidad": item?.amount,
                        "modelo": [item?.model]
                    }
                })
            }

            return {
                "codigo": cert.id,
                "info_cert": setItems
            }
        })

        

        let allCertificationCodeInsideList = []
        allCertificationCodeInsideList = Object.values(certifications).map(cert => {
            const { code } = cert
            return code
        })

        equipos = equipos.filter(item => {
            return allCertificationCodeInsideList.includes(item.codigo)
        })

        let isValidRequest = true
        for (let i = 0; i < equipos.length; i++) {

            const find_cert_in_princial_list = Object.values(certificationSelectedWithInputs).find(x => {
                return x.id === equipos[i].codigo
            })

            if (equipos[i].info_cert.length === 0) {
                toast('El certificado ' + find_cert_in_princial_list?.nameCertificate + ' no tiene elementos seleccionados, por favor ingrese al menos un item', { type: 'error', autoClose: 30000 });
                isValidRequest = false;
            }
        }

        if (!isValidRequest) {
            return;
        }

        let IsValidadCount = true
        for (let i = 0; i < equipos.length; i++) {

            let max_items_in_certificate = equipos[i]?.info_cert.length
            const find_cert_in_princial_list = Object.values(certificationSelectedWithInputs).find(x => {
                return x.id === equipos[i].codigo
            })

            let max_items_in_software = 0
            if (equipos[i].codigo === "CER_EQU_009") {
                max_items_in_software = equipos[i]?.info_cert[0]?.modelo.length
            }

            if (find_cert_in_princial_list?.counterLines !== (max_items_in_certificate + max_items_in_software)) {
                toast('El certificado ' + find_cert_in_princial_list?.nameCertificate + ' tiene items sin complear, por favor verifique que todos los campos estan llenos o eliminar los que no estan completos', { type: 'error', autoClose: 30000 });
                IsValidadCount = false;
            }

            if (find_cert_in_princial_list?.id === "CER_EQU_008" || find_cert_in_princial_list?.id === "CER_EQU_009") {
                for (let x = 0; x < find_cert_in_princial_list?.items.length; x++) {
                    const element = find_cert_in_princial_list?.items[x]
                    if (element?.nombres?.length !== 10) {
                        IsValidadCount = false;
                    }
                }

                if (!IsValidadCount) {
                    toast('El certificado ' + find_cert_in_princial_list?.nameCertificate + ' tiene cedulas que no tienen un máximo de 10 digitos', { type: 'error', autoClose: 30000 });
                }
            }
        }

        if (!IsValidadCount) {
            return;
        }

        try {

            const { code } = match.params

            if (validateDataProcess() && validateCertificatedSelected(allCertificationCodeInsideList)) {

                // set the header of the request
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

                const sector = isPublic ? 'PUBLICO' : 'PRIVADO'

                if (isPublic) {

                    requestUrl({
                        // i need to get the url of the request
                        url: '/auth/solicitud/edit/equipo',
                        method: 'PUT',
                        headers: myHeaders,
                        body: {
                            "codigo": code,
                            "ruc": profile.ruc,
                            "descripcion": profile.razonSocial,
                            "tipo": "EQUIPO",
                            "mayorista": dataProcess.proveedor,
                            "sector": sector,
                            "datosSectorPublico": dataProcess,
                            "certificados": allCertificationCodeInsideList,
                            "equipos": equipos,
                            "observaciones": "",
                        }
                    }, sendEdithRequestTeamsDataResponse)

                } else {
                    requestUrl({
                        // i need to get the url of the request
                        url: '/auth/solicitud/edit/equipo',
                        method: 'PUT',
                        headers: myHeaders,
                        body: {
                            "codigo": code,
                            "ruc": profile.ruc,
                            "descripcion": profile.razonSocial,
                            "tipo": "EQUIPO",
                            "mayorista": dataProcess.proveedor,
                            "sector": sector,
                            "datosSectorPrivado": dataProcess,
                            "certificados": allCertificationCodeInsideList,
                            "equipos": equipos,
                            "observaciones": "",
                        }
                    }, sendEdithRequestTeamsDataResponse)
                }
            }


        } catch (er) {
            console.log(er)
        }
    }


    const approveRequestAdminFunctionDataResponse = (response) => {
        history.push('/request-approved')
    }

    const approveRequestAdminFunction = () => {
        try {

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/aprobar',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoSolicitud": code
                }
            }, approveRequestAdminFunctionDataResponse)

        } catch (er) {
            console.log(er)
        }

    }

    const denyRequestAdminFunctionDataResponse = () => {
        history.push('/request-rejected')
    }

    const denyRequestAdminFunction = () => {
        try {

            const { code } = match.params

            // set the header of the request
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            requestUrl({
                // i need to get the url of the request
                url: '/auth/solicitud/rechazar',
                method: 'POST',
                headers: myHeaders,
                body: {
                    "codigoSolicitud": code,
                    "motivoRechazo": reasonRejection
                }
            }, denyRequestAdminFunctionDataResponse)

        } catch (er) {
            console.log(er)
        }
    }

    /************************************************************************** */
    /************************************************************************** */


    return (
        <React.Fragment>
            {request && (

                <div className="page-content">
                    <Container fluid={true}>

                        <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>

                            <h1 className="form-label mb-3"
                                style={{ fontWeight: 'bold' }}
                            >
                                SOLICITAR CERTIFICADO {typeSolicitation.toUpperCase()}
                            </h1>

                            {request.motivoRechazo && (
                                <div>
                                    <div className=" alert alert-danger" role="alert">
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>SOLICITUD RECHAZADO:</span>  {request.motivoRechazo}
                                    </div>
                                </div>
                            )}

                            <Col xl="12" style={{ width: '100%' }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>

                                        <AvForm className="needs-validation" >
                                            <Row className="d-flex justify-content-center ">
                                                <Col xl="3" className="d-flex flex-column justify-content-around" >
                                                    <div style={{ marginBottom: 10 }} >

                                                        <div>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                CANAL
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCursorFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {request.razon_social ? request.razon_social : profile.razonSocial}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <Col xl="4" className="d-flex flex-column justify-content-end">
                                                        <div style={{ marginBottom: 10 }}>
                                                            <div>
                                                                <h6 style={{ fontWeight: 'bold' }}>
                                                                    RUC
                                                                </h6>
                                                            </div>

                                                            <div className="d-flex align-items-center">
                                                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                    <BsReverseLayoutTextSidebarReverse />
                                                                </i>
                                                                <h6 style={{ margin: 0 }}>
                                                                    {request.ruc ? request.ruc : profile.ruc}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Col>

                                                <Col xl="3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="d-flex flex-column justify-content-center " style={{ marginBottom: 10 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                TIPO DE CERTIFICADO
                                                            </h6>
                                                        </div>

                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsBarChartSteps />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {typeSolicitation.toUpperCase()}
                                                            </h6>
                                                        </div>
                                                    </div>


                                                    <div className="d-flex flex-column justify-content-center ">
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                FECHA SOLICITUD
                                                            </h6>
                                                        </div>
                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                <BsFillCalendarCheckFill />
                                                            </i>
                                                            <h6 style={{ margin: 0 }}>
                                                                {dateRequest}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xl="3" style={{ display: 'flex', flexDirection: 'column' }} >

                                                    <div className="d-flex flex-column justify-content-center ">

                                                        <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                            <h6 style={{ fontWeight: 'bold' }}>
                                                                ESTADO
                                                            </h6>
                                                        </div>
                                                        {
                                                            request.estado === 'PENDIENTE' ?
                                                                (
                                                                    <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                        <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                            <BsFillStopCircleFill style={{ color: '#FFDB58', fontWeight: 'bold' }} />
                                                                        </i>
                                                                        <h6 style={{
                                                                            margin: 0, fontWeight: 'bold', color: '#FFDB58', //WebkitTextStroke: '.5px black'
                                                                        }}>
                                                                            PENDIENTE
                                                                        </h6>
                                                                    </div>
                                                                ) : request.estado === 'RECHAZADO' ?
                                                                    (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                        <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                            <BsFillXCircleFill style={{ color: 'red', fontWeight: 'bold' }} />
                                                                        </i>
                                                                        <h6 style={{
                                                                            margin: 0, fontWeight: 'bold', color: 'red', //WebkitTextStroke: '.5px black'
                                                                        }}>
                                                                            RECHAZADO
                                                                        </h6>
                                                                    </div>)
                                                                    : request.estado === 'APROBADO' ?
                                                                        (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                            <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                <BsFillPatchCheckFill style={{ color: 'green', fontWeight: 'bold' }} />
                                                                            </i>
                                                                            <h6 style={{
                                                                                margin: 0, fontWeight: 'bold', color: 'green', //WebkitTextStroke: '.5px black'
                                                                            }}>
                                                                                APROBADO
                                                                            </h6>
                                                                        </div>)
                                                                        : request.estado === 'REINGRESO' ?
                                                                            (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                                <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                    <BsFillVinylFill style={{ color: '#ff8000', fontWeight: 'bold' }} />
                                                                                </i>
                                                                                <h6 style={{
                                                                                    margin: 0, fontWeight: 'bold', color: '#ff8000', //WebkitTextStroke: '.5px black'
                                                                                }}>
                                                                                    REINGRESO
                                                                                </h6>
                                                                            </div>)
                                                                            : request.estado ?
                                                                                (<div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                                                                                    <i style={{ fontSize: 30, marginRight: 10 }}>
                                                                                        <BsFillPencilFill style={{ color: '#FFDB58', fontWeight: 'bold' }} />
                                                                                    </i>
                                                                                    <h6 style={{
                                                                                        margin: 0, fontWeight: 'bold', color: '#FFDB58', //WebkitTextStroke: '.5px black'
                                                                                    }}>
                                                                                        EDITANDO
                                                                                    </h6>
                                                                                </div>) : ''

                                                        }
                                                    </div>
                                                </Col>
                                            </Row>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl="12" style={{ width: '100%', marginTop: 20 }} id="datos" >
                                <Card style={{ height: '100%' }} >
                                    <CardBody>
                                        <AvForm className="needs-validation" >
                                            <Row >
                                                <Col xl="12" >
                                                    {isAdmin && request.sector ? (
                                                        <SelectSectorAdmin handleChangeView={handleChangeView} sector={request.sector} />
                                                    ) : !isAdmin && (request.estado === 'RECHAZADO') ? (
                                                        <SelectSector handleChangeView={handleChangeView} sector={request.sector ? request.sector : ''} />
                                                    ) : request.sector ? (
                                                        <SelectSectorAdmin handleChangeView={handleChangeView} sector={request.sector} />
                                                    ) : !request.sector ? (
                                                        <SelectSectorChannel handleChangeView={handleChangeView} />
                                                    ) : ''}
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Row style={{ paddingRight: 0 }}>

                                {/* SHOW THE PUBLIC SESCTOR */}
                                <Col xl="12" style={{ width: '70%', marginTop: 20 }} id="certificados" >
                                    <Card style={{ height: '100%' }}>
                                        <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                                            {isPublic && !isAdmin && (request.estado === 'RECHAZADO' || !request.estado) ? (
                                                < PublicSector
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess ? request.dataProcess : ''}
                                                    mayorista={request.mayorista ? request.mayorista : ''}
                                                />

                                            ) : !isAdmin && (request.estado === 'RECHAZADO' || !request.estado) ? (
                                                <PrivateSector
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess ? request.dataProcess : ''}
                                                    mayorista={request.mayorista ? request.mayorista : ''}

                                                />
                                            ) : isPublic && !isAdmin ? (
                                                < PublicSectorAdmin
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess}
                                                    mayorista={request.mayorista ? request.mayorista : ''}
                                                />
                                            ) : !isPublic && !isAdmin ? (
                                                < PrivateSectorAdmin
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess}
                                                    mayorista={request.mayorista ? request.mayorista : ''}

                                                />
                                            ) : ''}

                                            {isAdmin && (request.sector === 'PRIVADO') ? (
                                                < PrivateSectorAdmin
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess}
                                                    mayorista={request.mayorista ? request.mayorista : ''}
                                                />
                                            ) : isAdmin && (request.sector === 'PUBLICO') ? (
                                                < PublicSectorAdmin
                                                    handleChangeDataProcess={handleChangeDataProcess}
                                                    dataProcess={request.dataProcess}
                                                    mayorista={request.mayorista ? request.mayorista : ''}
                                                />
                                            ) : ''}

                                        </CardBody>
                                    </Card>
                                </Col>

                                {/* DATOS DEL PROCESO - SECTOR PUBLICO O PRIVADO */}
                                <Col xl="12" style={{ width: '30%', marginTop: 20, padding: 0 }} id="datos_proceso" >
                                    <Card style={{ height: '100%' }}>
                                        <CardBody style={{ height: '100%' }}>

                                            {isAdmin && request.certificados ? (
                                                < TeamsAdmin
                                                    addToListTeams={addCertificationIntoListTeams}
                                                    removeToListTeams={removeCertificationIntoListTeams}
                                                    certificationSelected={request.certificados}
                                                    estado={request.estado ? request.estado : ''}
                                                    codeRequest={codeRequest ? codeRequest : ''}
                                                    tipoCanal={request.tipoCanal ? request.tipoCanal : ''}
                                                />
                                            ) : !isAdmin && (request.estado === 'RECHAZADO' || !request.estado) ? (

                                                < Teams
                                                    addToListTeams={addCertificationIntoListTeams}
                                                    removeToListTeams={removeCertificationIntoListTeams}
                                                    certificationSelected={request.certificados ? request.certificados : []}
                                                    estado={request.estado ? request.estado : ''}
                                                    codeRequest={codeRequest ? codeRequest : ''}
                                                />
                                            ) : !isAdmin ? (
                                                < TeamsAdmin
                                                    addToListTeams={addCertificationIntoListTeams}
                                                    removeToListTeams={removeCertificationIntoListTeams}
                                                    certificationSelected={request.certificados}
                                                    estado={request.estado ? request.estado : ''}
                                                    codeRequest={codeRequest ? codeRequest : ''}
                                                    tipoCanal={request.tipoCanal ? request.tipoCanal : ''}
                                                />
                                            ) : ''}

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {renderCertificationTeams}
                        </Row>
                        {isAdmin ? (
                            <>
                                <div className="d-flex justify-content-end mt-3">
                                    <div className="mx-3">
                                        <button
                                            className="btn btn-danger-lexmark mx-2"
                                            type="button"
                                            onClick={handleShow}
                                        >RECHAZAR</button>
                                        <button
                                            className="btn btn-primary-lexmark"
                                            type="button"
                                            onClick={approveRequestAdminFunction}
                                        >APROBAR</button>
                                    </div>
                                </div>
                            </>
                        ) : !isAdmin && request.estado === 'RECHAZADO' ? (
                            <div className="d-flex justify-content-end mt-3">
                                <div className="mx-3">
                                    <button
                                        className="btn btn-primary-lexmark"
                                        type="button"
                                        onClick={sendEdithRequestTeams}
                                    >RENVIAR SOLICITUD</button>
                                </div>
                            </div>
                        ) : !isAdmin && !request.estado ? (
                            <div className="d-flex justify-content-end mt-3">
                                <div className="mx-3">
                                    <button
                                        className="btn btn-primary-lexmark"
                                        type="button"
                                        onClick={sendRequestTeams}
                                    >SOLICITAR</button>
                                </div>
                            </div>
                        ) : ''}

                    </Container>
                </div>
            )}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>RECHAZAR SOLICITUD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Por favor ingrese el motivo por la cual rechazará la solicitud.
                    </p>
                    <input
                        type="text"
                        className="form-control"
                        value={reasonRejection}
                        onChange={(e) => setReasonRejection(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>
                        CERRAR
                    </button>
                    <Button className="btn btn-danger" onClick={denyRequestAdminFunction}>RECHAZAR</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    );

}

export default TeamCertificate;
