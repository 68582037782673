import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
  Search as SE,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

// import the redux
import { useSelector, useDispatch } from "react-redux";

// import lib to export to excel
import ReactHtmlTableToExcel from "react-html-table-to-excel";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import useHttp from "../../hook/use-http";
import { toast } from "react-toastify";

const DetailReportTeams = ({ history }) => {
  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  // get the data with redux dispatch
  const dispatch = useDispatch();
  const dataJson = useSelector((state) => state.GeneralReportsJsonConsume.data);

  // this will be used to set the data in a variable
  const [reports, setReports] = useState(dataJson);
  const [totalItems, setTotalItems] = useState(0);

  // this variable will be used to control the settings of the table
  const page = 1;
  const sizePerPage = 10;
  // save the product after add and extra fields
  const [reportsData, setReportsData] = useState([]);

  /*
    useEffect(() => {
        const result = []
 
        // add and extra field to products
        reports.map((item, index) => {
            item.id = index + 1
            result.push(item)
        });
 
        setReportsData(result);
 
    }, [])
    */

  // this function will be process the result of the method getListChannel
  const handleGetDetailReportDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      let result = [];

      // add and extra field to products
      products.forEach((item, index) => {
        item.id = index + 1;

        const codeProcess = item.datosSectorPublico?.codigoProceso
          ? item.datosSectorPublico?.codigoProceso
          : item.datosSectorPrivado?.codigoProceso;

        item.code_process =
          codeProcess?.length > 0 && codeProcess !== undefined
            ? codeProcess
            : "";

        item.tipoContratacion = item.datosSectorPublico?.tipoContratacion
          ? item.datosSectorPublico?.tipoContratacion
          : item.datosSectorPrivado?.tipoContratacion
          ? item.datosSectorPrivado?.tipoContratacion
          : "";

        item.objetoProceso = item.datosSectorPublico?.objetoProceso
          ? item.datosSectorPublico?.objetoProceso
          : item.datosSectorPrivado?.objetoProceso
          ? item.datosSectorPrivado?.objetoProceso
          : "";

        item.entidad = item.canal?.razonSocial;

        item.entidadContratante = item.datosSectorPublico?.entidadContratante
          ? item.datosSectorPublico?.entidadContratante
          : item.datosSectorPrivado?.entidadContratante
          ? item.datosSectorPrivado?.entidadContratante
          : "";

        item.entidad = item.canal?.razonSocial;
        item.codigo = item.codigo;
        item.date_puja = item.datosSectorPublico?.fechaInicioPuja;
        item.date_limit_question =
          item.datosSectorPublico?.fechaLimitePreguntas;
        item.date_limit_answer = item.datosSectorPublico?.fechaLimiteRespuestas;
        item.date_limit_offer =
          item.datosSectorPublico?.fechaLimiteEntregaOferta;
        item.date_limit_qualification =
          item.datosSectorPublico?.fechaLimiteCalificacion;
        item.plazo =
          item.datosSectorPublico?.plazoEntrega &&
          item.datosSectorPublico?.plazoEntrega + " ( dias )";
        item.contacto_funcionario =
          item.canal?.contactoPrincipal?.emailContacto;
        item.mayorista = item.mayorista;

        // Object.values(item?.equipos).forEach(team => {

        //     const info_cert = team?.info_cert

        //     if (info_cert !== undefined) {

        //         Object.values(info_cert).forEach(cert => {

        //             if (cert?.cantidad?.length > 0) {
        //                 teams.push({
        //                     pn: cert.modelo[0],
        //                     qty: cert.cantidad,
        //                 })
        //             }

        //         })
        //     }

        //     /*
        //     if (info_cert[0].cantidad.length > 0) {
        //         return {
        //             pn: info_cert[0].modelo[0],
        //             qty: info_cert[0].cantidad
        //         }
        //     }*/
        //     /*
        //     return info_cert[0].cantidad.length > 0 && {
        //         pn: info_cert[0].modelo[0],
        //         qty: info_cert[0].cantidad
        //     }
        //     */
        // })

        // Object.values(teams).forEach(x => {
        //     //const pn = x.info_cert[0].modelo[0]

        // })
        item.certificatesList = (
          <ul>
            {item.certificados.map((certificado, key) => (
              <li key={key}>{certificado}</li>
            ))}
          </ul>
        );
        item.certificatesTypeList = (
          <ul>
            {item.tiposCertificados.map((certificado, key) => (
              <li key={key}>{certificado}</li>
            ))}
          </ul>
        );

        result.push({
          date_puja: formatDateTime(item.date_puja),
          date_limit_question: formatDateTime(item.date_limit_question),
          date_limit_answer: formatDateTime(item.date_limit_answer),
          date_limit_offer: formatDateTime(item.date_limit_offer),
          date_limit_qualification: formatDateTime(item.date_limit_qualification),
          created_date: formatDateTime(item.createdDate),
          plazo: item.plazo,
          contacto_funcionario: item.contacto_funcionario,
          codigo: item.codigo,
          entidad: item.entidad,
          mayorista: item.mayorista,
          // pn: x.pn,
          // qty: x.qty,
          code_process: item.code_process,
          entidadContratante: item.entidadContratante,
          tipoContratacion: item.tipoContratacion,
          objetoProceso: item.objetoProceso,
          sector: item.sector,
          certificatesList: item.certificatesList,
          certificatesTypeList: item.certificatesTypeList,
          tiposCertificados: item.tiposCertificados,
          certificados: item.certificados
        });
      });

      setReportsData(result);
      setTotalItems(result.length);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };
  // this function will be used to request the data from the server
  const getDetailReport = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/solicitud/reportes/reporteDetalladoSolicitudesEquipos",
          method: "GET",
          headers: myHeaders,
        },
        handleGetDetailReportDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetailReport();
  }, []);

  const { ExportCSVButton } = CSVExport;

  const columns = [
    {
      dataField: "sector",
      text: "SECTOR",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "entidad",
      text: "CANAL",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "entidadContratante",
      text: "ENTIDAD",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    // {
    //     dataField: 'pn',
    //     text: 'PN',
    //     sort: true,
    //     filter: textFilter({
    //         placeholder: `${' '}`,
    //         style: {
    //             width: 'auto',
    //             marginLeft: '10px',
    //             paddingRight: '10px',
    //         }
    //     }),
    //     sortCaret: (order, column) => {
    //         if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
    //         else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
    //         else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
    //         return null;
    //     }
    // },
    // {
    //     dataField: 'qty',
    //     text: 'QTY',
    //     sort: true,
    //     filter: textFilter({
    //         placeholder: `${' '}`,
    //         style: {
    //             width: 'auto',
    //             marginLeft: '10px',
    //             paddingRight: '10px',
    //         }
    //     }),
    //     sortCaret: (order, column) => {
    //         if (!order) return (<span>&nbsp;&nbsp;<BsFillCaretUpFill />/ < BsFillCaretDownFill /> </span>);
    //         else if (order === 'asc') return (<span>&nbsp;&nbsp;< BsFillCaretDownFill />/<font color="black"> <BsFillCaretUpFill /> </font></span>);
    //         else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black"><BsFillCaretUpFill /></font>/< BsFillCaretDownFill /></span>);
    //         return null;
    //     }
    // },
    {
      dataField: "certificatesList",
      text: "CERTIFICADOS",
      sort: true,
      csvFormatter : (data, rowData) => {
        return rowData.certificados.join(', ')
      },
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "certificatesTypeList",
      text: "TIPOS DE CERTIFICADOS",
      sort: true,
      csvFormatter : (data, rowData) => {
        return rowData.tiposCertificados.join(', ')
      },
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "codigo",
      text: "CODIGO SOLICITUD",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "code_process",
      text: "CODIGO DEL PROCESO",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "tipoContratacion",
      text: "TIPO DE CONTRATACION",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "objetoProceso",
      text: "OBJETO DEL PROCESO",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "created_date",
      text: "FECHA SOLICITUD",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "date_puja",
      text: "FECHA PUJA",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "date_limit_question",
      text: "FECHA LÍMITE DE PREGUNTAS",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "date_limit_answer",
      text: "FECHA LÍMITE DE RESPUESTAS",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "date_limit_offer",
      text: "FECHA LÍMITE DE OFERTA",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "date_limit_qualification",
      text: "FECHA LIMITE DE CALIFICACION",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    /*
        {
            dataField: 'canal_ajudicado',
            text: 'CANAL AJUDICADO',
            sort: true
        },
        */
    /*
        {
            dataField: 'valor_final_puja',
            text: 'VALOR FINAL PUJA',
            sort: true
        },
        {
            dataField: 'estado_proceso',
            text: 'ESTADO',
            sort: true
        },
        */
    {
      dataField: "mayorista",
      text: "MAYORISTA",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    /*
        {
            dataField: 'mes_ajudicado',
            text: 'MES AJUDICADO',
            sort: true
        },
        */
    {
      dataField: "plazo",
      text: "PLAZO DE ENTREGA",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
    {
      dataField: "contacto_funcionario",
      text: "CONTACTO FUNCIONARIO",
      sort: true,
      filter: textFilter({
        placeholder: `${" "}`,
        style: {
          width: "auto",
          marginLeft: "10px",
          paddingRight: "10px",
        },
      }),
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretUpFill />/ <BsFillCaretDownFill />{" "}
            </span>
          );
        else if (order === "asc")
          return (
            <span>
              &nbsp;&nbsp;
              <BsFillCaretDownFill />/
              <font color="black">
                {" "}
                <BsFillCaretUpFill />{" "}
              </font>
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              &nbsp;&nbsp;
              <font color="black">
                <BsFillCaretUpFill />
              </font>
              /<BsFillCaretDownFill />
            </span>
          );
        return null;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalItems, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;
  const E = SE.SearchBar;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={reportsData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={reportsData}
                        search={{
                          searchText: "",
                        }}
                        exportCSV={{
                          fileName: "reportsDetails.csv",
                          blobType: "text/csv;charset=ansi",
                        }}
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2 d-flex justify-content-between">
                              {/*
                                                            <Col md="12">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type="text"
                                                                            className="filter text-filter form-control"
                                                                            delay="500"
                                                                            value={searchByName}
                                                                            onChange={(e) => filterByNameValue(e.target.value)}
                                                                        />
                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <label htmlFor="">CODIGO DEL PROCESO</label>
                                                                        <input type="text" className="form-control" />

                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <label htmlFor="">FECHA DE PUJA</label>
                                                                        <input type="datetime-local" className="form-control" />

                                                                        <i className="search-box chat-search-box" />
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                             */}
                              <Col className="d-flex justify-content-end">
                                {/* 
                                                                < ReactHtmlTableToExcel
                                                                    id="buttonToExportExcel"
                                                                    className="btn btn-success"
                                                                    table="table-responsive"
                                                                    filename="reportsDetails"
                                                                    sheet="Page 1"
                                                                    buttonText="Export to Excel"
                                                                />
                                                                */}
                                <ExportCSVButton
                                  {...toolkitProps.csvProps}
                                  className="btn btn-success"
                                >
                                  Export to Excel
                                </ExportCSVButton>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="table-responsive"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-responsive-custom-lexmark"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    filter={filterFactory()}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            {/*

                                                        <div className="d-flex justify-content-end" >
                                                            < ReactHtmlTableToExcel
                                                                id="buttonToExportExcel"
                                                                className="btn btn-success"
                                                                table="table-responsive"
                                                                filename="reportsDetails"
                                                                sheet="Page 1"
                                                                buttonText="Export to Excel"
                                                            />
                                                        </div>
                                                         */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailReportTeams;
