import React, { useState, useEffect } from "react"
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { addCounterLineInCertificateById } from "../../store/actions";


const Item = ({ id, addItemIntoListCertification, ciudadInput, direccionInput, removeItemIntoListCertification, code }) => {

    const [isAdmin, setIsAdmin] = useState(false);
    const statusCertification = useSelector(state => state.TeamCertifications.statusCertification)

    const dispatch = useDispatch()

    // this variable will be uses to create a technical certification
    const [ciudad, setCiudad] = useState(ciudadInput)
    const [direccion, setDireccion] = useState(direccionInput)

    useEffect(() => {
        setCiudad(ciudadInput)
        setDireccion(direccionInput)
    }, [ciudadInput, direccionInput])

    // this variable represent if the component is or not deleted
    const [isDeleted, setIsDeleted] = useState(false)

    useEffect(() => {
        dispatch(addCounterLineInCertificateById(code))
    }, [])

    // this variable control if the component was clicked 
    const [isClicked, setIsClicked] = useState(false)

    // this will be used to send the product to the parent component
    // but this will be send only if the all inputs are completed
    useEffect(() => {

        if (ciudad.trim() !== '' && direccion.trim() !== '') {
            addItemIntoListCertification({ id, ciudad, direccion })
        }

    }, [ciudad, direccion])

    // this function will be used to delete the component
    const deleteComponentClicked = () => {
        setIsDeleted(true)
        removeItemIntoListCertification(id)
    }

    return (
        <>  {!isDeleted && (
            <div>
                <div className="row mb-1 mt-3">
                    <div className="col-6">
                        <p style={{ fontWeight: 'bold' }}>
                            CIUDAD
                        </p>
                    </div>
                    <div className="col-6">
                        <p style={{ fontWeight: 'bold' }}>
                            DIRECCION
                        </p>
                    </div>
                </div>

                <div className="row">

                    {isAdmin ? (
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                value={ciudad}
                                onChange={(e) => setCiudad(e.target.value)}
                                disabled
                            />
                        </div>
                    ) : (

                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                value={ciudad}
                                onChange={(e) => setCiudad(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}
                            />
                        </div>
                    )}

                    {isAdmin ? (
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                value={direccion}
                                onChange={(e) => setDireccion(e.target.value)}
                            />
                        </div>
                    ) : (

                        <div className="col-6 d-flex">
                            <input
                                className="form-control"
                                type="text"
                                value={direccion}
                                onChange={(e) => setDireccion(e.target.value)}
                                disabled={statusCertification === 'RECHAZADO' || statusCertification === '' ? false : true}
                            />
                            {statusCertification === 'RECHAZADO' && (
                                <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                    deleteComponentClicked
                                }>

                                    < BsFillXCircleFill />

                                </button>
                            )}

                            {statusCertification === '' && (
                                <button className="btn btn-danger-lexmark" style={{ marginLeft: 20 }} onClick={
                                    deleteComponentClicked
                                }>

                                    < BsFillXCircleFill />

                                </button>
                            )}
                        </div>
                    )}

                </div>
            </div>
        )}
        </>
    )

}

export default Item;