import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import useHttp from "../../hook/use-http";

import "./datatables.scss";
import { toast } from "react-toastify";

import {formatDateTime, formatDateHour} from "../Utility/FormatTime"

export default function CatalogoElectronicoReporte({ history }) {
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState(0);
  const { SearchBar } = Search;

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/util/list`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDataResponse = (data) => {
    if (data && data.error) {
      toast("Ha ocurrido un error, la data no ha sido cargado, por favor.", {
        type: "error",
        autoClose: 10000,
      });
      return;
    }

    if (data.status === 401) {
      // show a error message here

      if (data.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(data.message, { type: "error", autoClose: 10000 });
    } else {
      const dataClean = data.map((item, index) => ({
        ...item,
        index: index + 1,
        name: item.name || "No info",
        email: item.email || "No info",
        comments: item.comments.length > 0 ? item.comments : ["No info"],
        country: item.country.length > 0 ? item.country : ["No info"],
        region: item.region.length > 0 ? item.region : ["No info"],
        date: formatDateTime(item.createdDate),
        hour: formatDateHour(item.createdDate),
      }));

      console.log(dataClean);
      setDataList(dataClean.length);
      setData(dataClean);
    }
  };

  //Data Estatica
  const columns = [
    {
      dataField: "index",
      text: "ID",
      sort: true,
    },
    {
      dataField: "date",
      text: "FECHA",
      sort: true,
    },
    {
      dataField: "hour",
      text: "HORA",
      sort: true,
    },
    {
      dataField: "name",
      text: "NOMBRE",
      sort: true,
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
    },
    {
      dataField: "country",
      text: "PAÍS",
      sort: true,
    },

    {
      dataField: "region",
      text: "REGIÓN",
      sort: true,
    },
    {
      dataField: "visit",
      text: "VISTAS",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: dataList,
    custom: true,
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={data}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="table-responsive"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
