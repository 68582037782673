import React, { Component, useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import the redux
import { useSelector, useDispatch } from "react-redux";

// import the components bootstrap
import { Button as B, Modal as M } from "react-bootstrap";

//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { toast } from "react-toastify";

import useHttp from "../../hook/use-http";
import ModalCustom from "../../components/Modal";
import {components} from "react-select";

//Import Breadcrumb
//import Breadcrumbs from '../../components/Common/Breadcrumb';

/* This array represent different provider */

/* This array represent the list of type of channel */

const ConfiguratorRegister = ({ history }) => {
  // this variable will be used to control the result of the form
  const [messageRequest, setMessageRequest] = useState("");
  // this variable will control the status of the message response
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);

  const [items, setItems] = useState([]);
  const [itemsDescription, setItemsDescription] = useState([]);
  const [itemsPreview, setItemsPreview] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [softwareList, setSoftwareList] = useState({});
  const [softwareListGrouped, setSoftwareListGrouped] = useState({});

  const [softwareSelectedLPM, setSoftwareSelectedLPM] = useState({});
  const [softwareSelectedSMSA, setSoftwareSelectedSMSA] = useState({});

  const [itemSelectedEdit, setItemSelectedEdit] = useState({});

  // this function will be used to request the server to login the user
  const { loading, error, request } = useHttp();

  const [entities, setEntities] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [nameOpportunity, setNameOpportunity] = useState();
  const [nameEntity, setNameEntity] = useState();

  // this will be used to control de component modal
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [volume, setVolume] = useState(0);
  const [searchPrinter, setSearchPrinter] = useState("");
  const [printers, setPrinters] = useState([]);

  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (showEdit) {
      getPrinters(); // Disparar la función getPrinters cuando el modal se abra
      console.log(itemSelected);
    }
  }, [showEdit]); // Dependencia para ejecutar cuando el modal se muestre

  useEffect(() => {
    // Si estamos en modo edición y no se ha seleccionado ninguna impresora, seleccionar la primera
    if (
      showEdit &&
      printers.length > 0 &&
      Object.keys(itemSelected).length === 0
    ) {
      console.log(itemSelectedEdit);

      // Actualizar selected en los componentes que coincidan con itemSelectedEdit
      const updatedComponents = printers[0].components.map((component) => {
        // Compara el partNumber de itemSelectedEdit con los componentes de la impresora
        const isSelected = itemSelectedEdit.components.some(
          (editItem) => editItem.partNumber === component.partNumber
        );

        // Si coincide, marcar como seleccionado
        return { ...component, selected: isSelected };
      });

      // Actualizar itemSelected con los componentes actualizados
      setItemSelected({
        ...itemSelectedEdit,
        components: updatedComponents,
      });

      console.log(printers[0]);
    }
  }, [showEdit, printers, itemSelected, itemSelectedEdit]);

  let profile = useSelector((state) => state.profileReducer.profile);

  const handleClose = () => {
    if (
      itemSelected &&
      Object.keys(itemSelected).length > 0 &&
      itemSelected.components &&
      itemSelected.components.filter((child) => child.selected)
    ) {
      itemSelected.components.forEach((item) => {
        item.selected = false;
      });
      setItemSelected(itemSelected);
    }
    setItemSelected({});
    setSearchPrinter("");
    setVolume(0);
    setPrinters([]);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleCloseEdit = () => {
    if (
      itemSelected &&
      Object.keys(itemSelected).length > 0 &&
      itemSelected.components &&
      itemSelected.components.filter((child) => child.selected)
    ) {
      itemSelected.components.forEach((item) => {
        item.selected = false;
      });
      setItemSelected(itemSelected);
    }
    setItemSelected({});
    setSearchPrinter("");
    setVolume(0);
    setPrinters([]);
    setShowEdit(false);
  };

  const handleShowEdit = () => setShow(true);

  const handleRedirectToListWhenTheConfiguratorIsCreated = () => {
    history.push(`/configurator-list`);
  };

  useEffect(() => {
    getEntities();
    getSoftware();
  }, []);
  /***************************************************************/
  /***************************************************************/
  /*******  declare state variables to create a new channel ******/
  /***************************************************************/
  /***************************************************************/
  const [form, setForm] = useState({
    partNumber: null,
    group: null,
    type: null,
    model: null,
    description: null,
    performance: null,
    localPrice: null,
    status: "Activo",
  });

  const columns = [
    {
      dataField: "partNumber",
      text: "Num. DE PARTE",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      sort: true,
      isNumberWithouthDecimal: true,
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Local US",
      sort: true,
    },
  ];

  const columnsParent = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "performanceProvision",
      text: "Rendimiento con provisión",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "monthVolume",
      text: "Volumen B/N",
      isNumberWithouthDecimal: true,
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "monthVolumeColor",
      text: "Volumen Color",
      isNumberWithouthDecimal: true,
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "quantity",
      text: "Cantidad x mes",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Tot. x mes",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "quantityTotalContrato",
      text: "Cantidad Total Contrato",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPriceContract",
      text: "Precio Tot. x contrato",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      className: "w-100-px",
    },
  ];

  const columnsPreviewParent = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "type",
      text: "tipo",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "performance",
      text: "Rendimiento",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "performanceProvision",
      text: "Rendimiento con provisión",
      isNumberWithouthDecimal: true,
      sort: true,
    },
    {
      dataField: "quantityNumber",
      text: "Cantidad x mes",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Tot. x mes",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "quantityTotalContrato",
      text: "Cantidad Total Contrato",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPriceContract",
      text: "Precio Tot. x contrato",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
  ];

  const columnsPreview = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "subgroup",
      text: "Subgrupo",
      sort: true,
    },
    {
      dataField: "group",
      text: "Grupo",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },

    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "model",
      text: "Modelo",
      sort: true,
    },
    {
      dataField: "quantityNumber",
      text: "Cantidad x mes",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "priceLocalUS",
      text: "Precio Referencial Unitario",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPrice",
      text: "Precio Tot. x mes",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "quantityTotalContrato",
      text: "Cantidad Total Contrato",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "totalPriceContract",
      text: "Precio Tot. x contrato",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
  ];

  const columnsSoftware = [
    {
      dataField: "indexGroup",
      text: "No.",
      sort: true,
    },
    {
      dataField: "partNumber",
      text: "PN",
      sort: true,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Cantidad de equipos",
      sort: true,
      className: "w-100-px text-right",
    },
    {
      dataField: "basePrice",
      text: "Base Enterprise Price MFP",
      isNumber: true,
      sort: true,
    },
    {
      dataField: "totalPrice",
      text: "Valor Total",
      sort: true,
      isNumber: true,
      className: "w-100-px text-right",
    },
  ];

  /* Create a function to validate each input is not empty */
  const validateInput = (input) => {
    return input !== "";
  };

  /* Create a function to validate each input the form */
  const validateForm = () => {
    return (
      validateInput(form.title) &&
      validateInput(form.description) &&
      validateInput(form.type)
    );
  };

  const compararPorEntidad = (a, b) => {
    // Utiliza toUpperCase() para hacer la comparación insensible a mayúsculas y minúsculas
    const entidadA = a.entidad.toUpperCase();
    const entidadB = b.entidad.toUpperCase();

    if (entidadA < entidadB) {
      return -1;
    }
    if (entidadA > entidadB) {
      return 1;
    }
    return 0; // Son iguales
  };

  // this function will be process the result of the method getListEntity
  const handleGetListSoftwareDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      setSoftwareList(
        products.map((software) => ({
          partNumber: software.numeroParte,
          description: software.descripcion,
          basePrice: software.precioReferencialUnitario,
          type: software.tipo,
        }))
      );
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getSoftware = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/v1/configurador/software/list",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListSoftwareDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  // this function will be process the result of the method getListEntity
  const handleGetListEntityDataResponse = (products) => {
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      products.sort(compararPorEntidad);
      setEntities([...products]);
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getEntities = () => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: "/auth/entity/active",
          method: "GET",
          headers: myHeaders,
        },
        handleGetListEntityDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetOpportunities = (products) => {
    if (!products) return;
    if (products.status === 400) {
      toast(products.message, { type: "info", autoClose: 10000 });
    }
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      const result = [];

      // add and extra field to products
      products.map((item, index) => {
        item.id = index + 1;

        let d = new Date(item.createdDate);
        let dformat =
          [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
          " " +
          [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

        item.createdDate = dformat;

        item.descripcion =
          item.descripcion?.length > 20
            ? item.descripcion?.slice(0, 20) + "..."
            : item.descripcion;

        // item.checkbox = <input type="checkbox" onClick={() => handleSelectOpportunity(item)} />

        result.push(item);
      });

      const res = result.sort((a, b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });

      setOpportunities(res);
    }
  };

  const getOpportunities = (entidad) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      setOpportunities([]);

      //  url: `/auth/oportunidad/list/estado?estado=PENDIENTE`,
      request(
        {
          url: `/auth/oportunidad/list/entidad?entidad=${entidad}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetOpportunities
      );
    } catch (error) {
      console.log(error);
    }
  };

  const convertToBase64 = (file, f) => {
    try {
      console.log("file", file);
      //console.log('f', f);

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      //console.log('reader', reader);

      let base64 = reader?.result;
      console.log(base64);
      //setAcuerdoComercialBase64(base64);

      try {
        reader.onload = () => {
          //console.timeLog(reader)
          base64 = reader?.result;
          console.log(base64);
          setForm({ ...form, preview: base64, file: file[0] });
          return file;
        };
      } catch (error) {
        return file;
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleCreateConfiguratorDataResponse = (response) => {
    if (response.status && response.errorCode) {
      if (response.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(response.message, { type: "error", autoClose: 10000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      localStorage.setItem("code-created", response.codigoConfiguracion);
      history.push(`/configurator-created`);
    }
  };

  //Botón Guardar
  const handleSubmit = (error, values) => {
    try {
      // set the header of the request
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      const suministros = [];
      let position = 0;

      if (itemsPreview.filter((item) => item.group !== "Software").length > 0) {
        itemsPreview
          .filter((item) => item.group !== "Software")
          .forEach((item) => {
            item.children.forEach((child) => {
              if (!child.partNumberParent) {
                suministros.push({
                  numeroParte: child.partNumber,
                  cantidad: child.quantityNumber,
                  cantidadTotal: child.quantityTotalContrato,
                  cantidadMesesContrato: item.months,
                  position,
                });
              } else {
                suministros.push({
                  numeroParte: child.partNumber,
                  cantidad: child.quantityNumber,
                  cantidadTotal: child.quantityTotalContrato,
                  position,
                  numeroPartePadre: child.partNumberParent,
                });
              }
              position++;
            });
          });
      }

      const software = [];
      let positionSoftware = 0;
      const softwareItem = itemsPreview.find(
        (item) => item.group === "Software"
      );
      if (softwareItem) {
        softwareItem.children.forEach((child) => {
          software.push({
            numeroParte: child.partNumber,
            cantidad: child.quantityNumber,
            position: positionSoftware,
          });
          positionSoftware++;
        });
      }

      const body = {
        ruc: profile.ruc,
        oportunidadId: nameOpportunity,
        entidad: nameEntity,
        suministros: suministros,
        software: software,
      };

      request(
        {
          url: `/auth/v1/configurador/register`,
          method: "POST",
          headers: myHeaders,
          body,
        },
        handleCreateConfiguratorDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectSupplies = (partNumber) => {
    const aux = { ...itemSelected };
    const supply = itemSelected.components.find(
      (item) => item.partNumber === partNumber
    );
    supply.selected = supply.selected ? false : true;
    setItemSelected(aux);
  };

  const handleQuantityChange = (indexParent, index, value) => {
    const aux = [...items];
    aux[indexParent].components[index].quantityNumber = +value;

    if (aux[indexParent].components[index].subgroup === 'Opcionales') {
      aux[indexParent].components[index].quantityTotalContrato =
          +value;
    } else {
      aux[indexParent].components[index].quantityTotalContrato =
          +value * aux[indexParent].months;
    }

    aux[indexParent].components[index].totalPrice = (
      +value * +aux[indexParent].components[index].priceLocalUS
    ).toFixed(2);

    aux[indexParent].components[index].totalPriceContract = (
      +aux[indexParent].components[index].priceLocalUS *
      +aux[indexParent].components[index].quantityTotalContrato
    ).toFixed(2);

    aux[indexParent].subTotal = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +aux[indexParent].totalPrice
    ).toFixed(2);

    aux[indexParent].subTotalContract = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPriceContract,
        0
      ) + +aux[indexParent].totalPriceContract
    ).toFixed(2);

    console.log(`Estoy en handleQuantityChange `);
    console.log(aux);

    setItems(aux);
  };

  const handleQuantityParentChange = (indexParent, value) => {
    const aux = [...items];
    aux[indexParent].quantityNumber = +value;
    aux[indexParent].quantityTotalContrato = aux[indexParent].quantityNumber;
    aux[indexParent].components.forEach((child) => {
      if (child.performance) {
        let monthVolume = +aux[indexParent].monthVolume;
        let monthVolumeColor = +aux[indexParent].monthVolumeColor;
        let totalVolumenBlack = monthVolume * aux[indexParent].quantityNumber;
        let totalVolumeColor =
          monthVolumeColor * aux[indexParent].quantityNumber;
        let totalVolume = totalVolumeColor + totalVolumenBlack;
        let performance = +child.performance;
        let performanceProvision =
          isNaN(child.performance) ||
          isNaN(aux[indexParent].provisionPercentage)
            ? 0
            : Math.abs(
                Math.round(
                  (Number(child.performance) || 0) *
                    (Number(aux[indexParent].provisionPercentage) / 100 - 1)
                )
              );

        child.performanceProvision = performanceProvision;
        if (child.volumen === "black") {
          if (aux[indexParent].appliesProvision) {
            child.quantityNumber = Math.ceil(
              totalVolumenBlack / performanceProvision
            );
          } else {
            child.quantityNumber = Math.ceil(totalVolumenBlack / performance);
          }
        } else if (child.volumen === "color") {
          if (aux[indexParent].appliesProvision) {
            child.quantityNumber = Math.ceil(
              totalVolumeColor / performanceProvision
            );
          } else {
            child.quantityNumber = Math.ceil(totalVolumeColor / performance);
          }
        } else if (child.volumen === "total") {
          if (aux[indexParent].appliesProvision) {
            child.quantityNumber = Math.ceil(
              totalVolume / performanceProvision
            );
          } else {
            child.quantityNumber = Math.ceil(totalVolume / performance);
          }
        }
        if (child.subgroup === 'Opcionales') {
          child.quantityTotalContrato = (child.quantityNumber || 1);
        } else {
          child.quantityTotalContrato = (child.quantityNumber || 1) * aux[indexParent].months;
        }

        child.totalPrice = +(child.quantityNumber || 1) * +child.priceLocalUS;
        child.totalPriceContract =
          +child.priceLocalUS *
            +child.quantityTotalContrato;
      }
    });

    aux[indexParent].totalPrice = (
      +value * +aux[indexParent].priceLocalUS
    ).toFixed(2);

    aux[indexParent].totalPriceContract = (
      +aux[indexParent].priceLocalUS *
      +aux[indexParent].quantityTotalContrato
    ).toFixed(2);

    aux[indexParent].subTotal = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +aux[indexParent].totalPrice
    ).toFixed(2);

    aux[indexParent].subTotalContract = (
      aux[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPriceContract,
        0
      ) + +aux[indexParent].totalPriceContract
    ).toFixed(2);

    console.log(`Estoy en handleQuantityParentChange ${aux}`);
    console.log(aux);

    setItems(aux);
  };

  const deleteParent = (index) => {
    let auxItems = [...items];
    auxItems.splice(index, 1);

    setItems(auxItems);
  };

  const deleteChildren = (indexParent, indexChild) => {
    let auxItems = [...items];
    auxItems[indexParent].components.splice(indexChild, 1);

    auxItems[indexParent].subTotal = (
      auxItems[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPrice,
        0
      ) + +auxItems[indexParent].totalPrice
    ).toFixed(2);

    auxItems[indexParent].subTotalContract = (
      auxItems[indexParent].components.reduce(
        (acc, item) => acc + +item.totalPriceContract,
        0
      ) + +auxItems[indexParent].totalPriceContract
    ).toFixed(2);

    console.log(`Estoy en deleteChildren ${auxItems}`);

    setItems(auxItems);
  };

  const getDateToday = () => {
    let yourDate = new Date();

    // Convertir la fecha a la zona horaria de Ecuador (UTC-5)
    let ecuadorDate = new Date(
      yourDate.toLocaleString("en-US", { timeZone: "America/Guayaquil" })
    );

    return ecuadorDate.toISOString().split("T")[0];
  };

  const handleStep2 = () => {
    setStep(1);
    let softwareListNumber = softwareList.map((software) => ({
      ...software,
      quantityNumber: 0,
    }));

    // Agrupar de 2 en 2
    const groupedSoftware = [];
    for (let i = 0; i < softwareListNumber.length; i += 2) {
      groupedSoftware.push(softwareListNumber.slice(i, i + 2));
    }

    setSoftwareListGrouped(groupedSoftware);
    //console.log(softwareListGrouped);
  };

  // Función que separa el softwareListGrouped en LPM y SMSA
  const separateSoftwareGroups = () => {
    const lpm = [];
    const smsa = [];

    softwareListGrouped.forEach((group) => {
      if (group[0].type.includes("Por número de impresoras")) {
        lpm.push(group[0]); // Asumiendo que los productos LPM están en group[0]
      }
      if (group[1].type.includes("Por año")) {
        smsa.push(group[1]); // Asumiendo que los productos SMSA están en group[1]
      }
    });

    setSoftwareSelectedLPM(lpm); // Asigna los productos LPM
    setSoftwareSelectedSMSA(smsa); // Asigna los productos SMSA

    //console.log(softwareSelectedLPM);
    //console.log(softwareSelectedSMSA);
  };

  //Aqui se controla preview y resumen al crear una cotización
  const handlePreview = () => {
    const previewArr = [];
    const itemsDescriptionArr = [];
    //console.log(items);
    items.forEach((item) => {
      itemsDescriptionArr.push(item);
      if (!previewArr.find((prev) => prev.subgroup === item.subgroup)) {
        previewArr.push({ subgroup: item.subgroup, children: [] });
      }
      previewArr
        .find((prev) => prev.subgroup === item.subgroup)
        .children.push(item);
      item.components.forEach((child) => {
        if (!previewArr.find((prev) => prev.subgroup === child.subgroup)) {
          previewArr.push({ subgroup: child.subgroup, children: [] });
        }
        previewArr
          .find((prev) => prev.subgroup === child.subgroup)
          .children.push(child);
      });
    });

    //let exist = false;
    if (Object.keys(softwareSelectedLPM).length > 0) {
      softwareSelectedLPM.forEach((softwareLPM) => {
        itemsDescriptionArr.push({
          partNumber: softwareLPM.partNumber,
          group: "Software",
          subgroup: "Software",
          type: softwareLPM.type,
          model: "",
          description: softwareLPM.description,
          performance: "",
          priceLocalUS: softwareLPM.basePrice,
          quantityNumber: softwareLPM.quantityNumber,
          subTotal:
            /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
              (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
          totalPrice:
            /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
              (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
          subTotalContract:
            /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
              (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
          totalPriceContract:
            /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
              (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
          components: [],
        });


        previewArr.push({
          group: "Software",
          subgroup: "Software",
          children: [
            {
              partNumber: softwareLPM.partNumber,
              group: "Software",
              subgroup: "Software",
              type: softwareLPM.type,
              model: "",
              description: softwareLPM.description,
              performance: "",
              priceLocalUS: softwareLPM.basePrice,
              quantityNumber: softwareLPM.quantityNumber,
              subTotal:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
                  (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
              subTotalContract:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
                  (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
              totalPrice:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
                  (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
              totalPriceContract:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareLPM.quantityNumber) * softwareLPM.basePrice,*/
                  (softwareLPM.quantityNumber || 0) * (softwareLPM.basePrice || 0),
            },
          ],
        });
        //exist = true;
      });
    }

    if (Object.keys(softwareSelectedSMSA).length > 0) {
      softwareSelectedSMSA.forEach((softwareSMSA) => {
        //if (exist) {
          /*itemsDescriptionArr.find(
            (desc) => desc.group === "Software"
          ).subTotal +=
            //(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
              //softwareSMSA.quantityNumber) * softwareSMSA.basePrice;
              softwareSMSA.quantityNumber * softwareSMSA.basePrice;*/

          itemsDescriptionArr
            .find((desc) => desc.group === "Software")
            .components.push({
              partNumber: softwareSMSA.partNumber,
              group: "Software",
              subgroup: "Software",
              type: softwareSMSA.type,
              model: "",
              description: softwareSMSA.description,
              performance: "",
              priceLocalUS: softwareSMSA.basePrice,
              quantityNumber: softwareSMSA.quantityNumber,
              subTotal:
                /*items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              totalPrice:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareSMSA.quantityNumber) * softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              subTotalContract:
                /*items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              totalPriceContract:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareSMSA.quantityNumber) * softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
            });
          previewArr
            .find((prev) => prev.group === "Software")
            .children.push({
              partNumber: softwareSMSA.partNumber,
              group: "Software",
              subgroup: "Software",
              type: softwareSMSA.type,
              model: "",
              description: softwareSMSA.description,
              performance: "",
              priceLocalUS: softwareSMSA.basePrice,
              quantityNumber: softwareSMSA.quantityNumber,
              subTotal:
                /*items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              totalPrice:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareSMSA.quantityNumber) * softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              subTotalContract:
                /*items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
              totalPriceContract:
                /*(items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                  softwareSMSA.quantityNumber) * softwareSMSA.basePrice,*/
                  (softwareSMSA.quantityNumber || 0) * (softwareSMSA.basePrice || 0),
            });
        //}
        /* else {
          itemsDescriptionArr.push({
            partNumber: softwareSMSA.partNumber,
            group: "Software",
            subgroup: "Software",
            type: softwareSMSA.type,
            model: "",
            description: softwareSMSA.description,
            performance: "",
            priceLocalUS: softwareSMSA.basePrice,
            quantityNumber: softwareSMSA.quantityNumber,
            subTotal:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
              softwareSMSA.basePrice,
            totalPrice:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSMSA.quantityNumber) * softwareSMSA.basePrice,
            subTotalContract:
              items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
              softwareSMSA.basePrice,
            totalPriceContract:
              (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                softwareSMSA.quantityNumber) * softwareSMSA.basePrice,
            components: [],
          });
          previewArr.push({
            group: "Software",
            subgroup: "Software",
            children: [
              {
                partNumber: softwareSMSA.partNumber,
                group: "Software",
                subgroup: "Software",
                type: softwareSMSA.type,
                model: "",
                description: softwareSMSA.description,
                performance: "",
                priceLocalUS: softwareSMSA.basePrice,
                quantityNumber: softwareSMSA.quantityNumber,
                subTotal:
                  items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                  softwareSMSA.basePrice,
                totalPrice:
                  (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||


                    softwareSMSA.quantityNumber) * softwareSMSA.basePrice,
                subTotalContract:
                  items.reduce((acc, item) => acc + +item.quantityNumber, 0) *
                  softwareSMSA.basePrice,
                totalPriceContract:
                  (items.reduce((acc, item) => acc + +item.quantityNumber, 0) ||
                    softwareSMSA.quantityNumber) * softwareSMSA.basePrice,
              },
            ],
          });
        }*/
      });
    }

    //console.log(itemsDescriptionArr);
    //console.log(previewArr);

    // Filter itemsDescriptionArr to exclude items with quantityNumber === 0
    //const filteredItemsDescriptionArr = itemsDescriptionArr.filter(item => item.quantityNumber !== 0);

// Filter previewArr to exclude items with quantityNumber === 0
    //const filteredPreviewArr = previewArr.filter(item => item.quantityNumber !== 0);

    // Use the filtered arrays as needed
    //setItemsDescription(filteredItemsDescriptionArr);
    //setItemsPreview(filteredPreviewArr);

    // Aplicar agrupación a ambos arrays

    const itemsDescriptionArrWithTotals = itemsDescriptionArr.map((item) => ({
      ...item, // Mantén las propiedades del item actual
      subTotal: (
          item.components.reduce((acc, x) => acc + safeNumber(x.totalPrice), 0)
          + safeNumber(item.totalPrice)
      ).toFixed(2),

      subTotalContract: (
          item.components.reduce(
              (acc, x) => acc + safeNumber(x.subTotalContract), 0
          ) + safeNumber(item.subTotalContract)
      ).toFixed(2)
    }));

    /*const previewArrWithTotals = previewArr.map((item) => ({
      ...item, // Mantén las propiedades del item actual
      subTotal: (
          item.components.reduce(
              (acc, x) => acc + +x.subTotal,
              0
          ) + +item.subTotal
      ).toFixed(2),
      subTotalContract: (
          item.components.reduce(
              (acc, x) => acc + +x.subTotalContract,
              0
          ) + +item.subTotalContract
      ).toFixed(2)
    }));*/


    const groupedItemsDescription = groupItemsDescription(itemsDescriptionArrWithTotals);
    const groupedItemsPreview = groupPreviewItems(previewArr);

    setItemsDescription(groupedItemsDescription);
    setItemsPreview(groupedItemsPreview);

    // console.log("Grouped Description:", groupedItemsDescription);
    //console.log("Grouped Preview:", groupedItemsPreview);

    setStep(2);
  };

  const safeNumber = (val) => isNaN(+val) ? 0 : +val;

  const groupItemsDescription = (items) => {

    return items
    .map(item => {
      // Filtramos los componentes dentro de cada item
      item.components = item.components.filter(component => component.quantityNumber > 0);
      return item;
    })
    .filter(item => item.quantityNumber > 0 || item.components.length > 0); // Filtramos los items que tengan quantityNumber > 0 o componentes válidos
  };

  const groupPreviewItems = (items) => {
    const grouped = {};
  
    items.forEach((item) => {
      const key = `${item.group}-${item.subgroup}`;
  
      if (!grouped[key]) {
        grouped[key] = {
          group: item.group,
          subgroup: item.subgroup,
          children: [],
        };
      }
  
      // Filtrar los hijos que tengan quantityNumber mayor a 0
      if (Array.isArray(item.children) && item.children.length > 0) {
        const validChildren = item.children.filter(child => child.quantityNumber > 0);
        grouped[key].children.push(...validChildren);
      }
    });
  
    return Object.values(grouped);
  };

  useEffect(() => {
    if (softwareList.length > 0) {
      separateSoftwareGroups();
    }
  }, [softwareListGrouped]);

  const handleGetListPrintersDataResponse = (products) => {
    if (products && products.error) {
      toast(
        "Ha ocurrido un error, no existe una base de datos de configuración activa, Active una base de datos de configuración e intentelo de nuevo, por favor.",
        { type: "error", autoClose: 10000 }
      );
      return;
    }
    if (products.status === 401) {
      // show a error message here

      if (products.errorCode === 12) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        history.push("/login");
      }

      toast(products.message, { type: "error", autoClose: 10000 });
    } else {
      let printersAux = Object.keys(products).map((itemsAux) => {
        const items = products[itemsAux];
        let printerParent = items.find((printer) => printer.impresora === true);
        let components = items.filter((printer) => printer.impresora === false);

        return {
          partNumber: printerParent.numeroParte,
          id: printerParent.id,
          group: printerParent.grupo,
          subgroup: printerParent.subgrupo.startsWith("Hardware -")
            ? "Hardware"
            : printerParent.subgrupo,
          type: printerParent.tipo,
          model: printerParent.modelo,
          description: printerParent.descripcion,
          performance: "",
          priceLocalUS: printerParent.precioReferencialUnitario,
          volumen: printerParent.volumen,
          components: components.map((component) => {
            return {
              partNumber: component.numeroParte,
              group: component.grupo,
              subgroup: component.subgrupo.startsWith("Hardware -")
                ? "Hardware"
                : component.subgrupo,
              type: component.tipo,
              model: component.modelo,
              description: component.descripcion,
              performance: component.rendimiento,
              priceLocalUS: component.precioReferencialUnitario,
              partNumberParent: component.numeroPartePadre,
              volumen: component.volumen,
            };
          }),
        };
      });

      setPrinters(printersAux);
      //setNameEntity(products[0].entidad);
    }
  };

  // this function will be used to request the data from the server
  const getPrinters = () => {
    setItemSelected({});
    try {
      // set the header of the request
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/v1/configurador/suministros/search/${searchPrinter}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetListPrintersDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuantityChangeSoftware = (groupIndex, itemIndex, value) => {
    let quantity = parseInt(value, 10);
  
    // Evitar valores negativos
    if (isNaN(quantity) || quantity < 0) {
      quantity = 0;
    }
  
    const updatedList = [...softwareListGrouped];
    updatedList[groupIndex][itemIndex].quantityNumber = quantity;
    setSoftwareListGrouped(updatedList);
  
    //console.log(softwareListGrouped);
  };

  const formatCurrency = (value) => {
    return `$${value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const getSubtotalSW = () => {
    return softwareListGrouped.reduce((total, group) => {
      return total + group[0].basePrice * group[0].quantityNumber;
    }, 0);
  };

  const getSubtotalSMSA = () => {
    return softwareListGrouped.reduce((total, group) => {
      return (
        total + (group[1] ? group[1].basePrice * group[1].quantityNumber : 0)
      );
    }, 0);
  };

  const getTotalQuantitySW = () => {
    return softwareListGrouped.reduce((total, group) => {
      return total + group[0].quantityNumber;
    }, 0);
  };

  const getTotalQuantitySMSA = () => {
    return softwareListGrouped.reduce((total, group) => {
      return total + (group[1] ? group[1].quantityNumber : 0);
    }, 0);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {messageStatusIsError === true && (
                    <Alert color="danger">{messageRequest}</Alert>
                  )}
                  {messageStatusIsError === false && (
                    <Alert color="success">{messageRequest}</Alert>
                  )}

                  <div className="step-progress-container">
                    <div
                      className={`step ${step >= 0 ? "active" : ""} text-left`}
                    >
                      Impresoras
                    </div>
                    <div
                      className={`step ${step >= 1 ? "active" : ""} m-negative`}
                    >
                      Software
                    </div>
                    <div
                      className={`step  ${
                        step >= 2 ? "active" : ""
                      } text-right`}
                    >
                      Previsualización
                    </div>
                  </div>
                  <div className="step-progress-container step-container">
                    <div className={`step ${step >= 0 ? "active" : ""}`}>
                      <span className="step-number  m-0 ">1</span>
                    </div>
                    <div className={`step ${step >= 1 ? "active" : ""}`}>
                      <span className="step-number">2</span>
                    </div>
                    <div className={`step  ${step >= 2 ? "active" : ""}`}>
                      <span className="step-number m-step-3">3</span>
                    </div>
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${step * 50}%` }}
                      ></div>
                    </div>
                  </div>
                  <br />
                  <br />

                  {step === 0 && (
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="6">
                          <h4 className="card-title">DATOS CONFIGURACIÓN</h4>
                        </Col>
                        <Col md="6">
                          <div className="text-right">
                            <Button
                              type="button"
                              className="btn btn-secondary-lexmark"
                              onClick={handleShow}
                            >
                              Agregar Configuración
                            </Button>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3 d-flex flex-column">
                            <Label
                              className="form-label"
                              htmlFor="validationCustom05"
                            >
                              Entidad
                            </Label>

                            <AvField
                              name="entidad"
                              type="select"
                              errorMessage="Ingrese la entidad"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={nameEntity}
                              onChange={(e) => {
                                setNameEntity(e.target.value);
                                setNameOpportunity(null);
                                getOpportunities(e.target.value);
                              }}
                            >
                              <option
                                value={""}
                                selected="true"
                                disabled="disabled"
                              >
                                Seleccione una entidad
                              </option>

                              {entities.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.entidad?.toUpperCase()}
                                >
                                  {item.entidad?.toUpperCase()}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col md="12">
                          <hr />
                          <br />
                          <div className="table-responsive">
                            <table className="custom-table">
                              <thead>
                                <tr>
                                  {columnsParent.map((column, index) => (
                                    <th className="text-center" key={index}>
                                      {column.text}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((item, indexParent) => (
                                  <React.Fragment key={item.index}>
                                    <tr>
                                      {columnsParent.map((column, index) => {
                                        if (column.dataField === "indexGroup")
                                          return (
                                            <td
                                              className="text-center parent-row color-black"
                                              key={index}
                                              rowspan={
                                                item.components.length + 2
                                              }
                                            >
                                              {indexParent + 1}
                                            </td>
                                          );

                                        return (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumberWithouthDecimal &&
                                            item[column.dataField]
                                              ? (+item[
                                                  column.dataField
                                                ]).toLocaleString("en-US", {
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0,
                                                })
                                              : column.isNumber &&
                                                item[column.dataField]
                                              ? (+item[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : item[column.dataField]}

                                            {column.dataField ===
                                              "quantity" && (
                                              <Input
                                                type="number"
                                                value={item.quantityNumber}
                                                onChange={(e) =>
                                                  handleQuantityParentChange(
                                                    indexParent,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            )}

                                            {column.dataField === "actions" && (
                                              <div className="text-center d-flex justify-content-evenly">
                                                <Button
                                                  size="sm"
                                                  type="button"
                                                  className="danger"
                                                  onClick={() =>
                                                    deleteParent(indexParent)
                                                  }
                                                >
                                                  {" "}
                                                  <i className="ri-delete-bin-line"></i>{" "}
                                                </Button>

                                                <Button
                                                  size="sm"
                                                  type="button"
                                                  className="danger"
                                                  onClick={() => {
                                                    setItemSelected(item); // Aquí se pasa el item que deseas editar
                                                    setItemSelectedEdit(item); // Aquí se pasa el item que deseas editar
                                                    setShowEdit(true); // Para mostrar el modal de edición
                                                    setSearchPrinter(
                                                      item.model
                                                    );
                                                  }}
                                                >
                                                  {" "}
                                                  <i className="ri-table-fill"></i>{" "}
                                                </Button>
                                              </div>
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                    {item.components.map(
                                      (child, indexChild) => (
                                        <tr
                                          key={child.index}
                                          className="child-row"
                                        >
                                          {columnsParent
                                            .filter(
                                              (column) =>
                                                column.dataField !==
                                                "indexGroup"
                                            )
                                            .map((column, index) => (
                                              <td
                                                key={index}
                                                className={column.className}
                                              >
                                                {column.dataField ===
                                                  "quantity" && (
                                                  <Input
                                                    type="number"
                                                    value={child.quantityNumber}
                                                    onChange={(e) =>
                                                      handleQuantityChange(
                                                        indexParent,
                                                        indexChild,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                )}

                                                {column.isNumberWithouthDecimal &&
                                                child[column.dataField]
                                                  ? (+child[
                                                      column.dataField
                                                    ]).toLocaleString("en-US", {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                    })
                                                  : column.isNumber &&
                                                    child[column.dataField]
                                                  ? (+child[column.dataField])
                                                      .toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD",
                                                      })
                                                      .replace(/\$/g, "$ ")
                                                  : child[column.dataField]}

                                                {column.dataField ===
                                                  "actions" && (
                                                  <div className="text-center">
                                                    <Button
                                                      size="sm"
                                                      type="button"
                                                      className="danger"
                                                      onClick={() =>
                                                        deleteChildren(
                                                          indexParent,
                                                          indexChild
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <i className="ri-delete-bin-line"></i>{" "}
                                                    </Button>
                                                  </div>
                                                )}
                                              </td>
                                            ))}
                                        </tr>
                                      )
                                    )}
                                    <tr key={`subtotal-indexParent`}>
                                      {columnsParent
                                        .filter(
                                          (column) =>
                                            column.dataField !== "indexGroup"
                                        )
                                        .map((column, index) => (
                                          <td
                                            key={index}
                                            className={`parent-row color-black ${column.className}`}
                                          >
                                            {index === 0 && "Subtotal"}
                                            {index ===
                                              columnsParent.length - 5 &&
                                              (+item.subTotal)
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(/\$/g, "$ ")}

                                            {index ===
                                              columnsParent.length - 3 &&
                                              (+item.subTotalContract)
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(/\$/g, "$ ")}
                                          </td>
                                        ))}
                                    </tr>
                                  </React.Fragment>
                                ))}
                                <tr>
                                  <td
                                    colSpan={columnsParent.length - 4}
                                    className="text-center"
                                  >
                                    Total
                                  </td>

                                  <td className="text-right">
                                    {(+items.reduce(
                                      (acc, item) => acc + +item.subTotal,
                                      0
                                    ))
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")}
                                  </td>
<td></td>
                                  <td className="text-right">
                                    {(+items.reduce(
                                      (acc, item) =>
                                        acc + +item.subTotalContract,
                                      0
                                    ))
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")}
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <br />

                      <br />

                      <div className="text-right">
                        <Button
                          className="btn btn-primary-lexmark box-shadow-none"
                          type="button"
                          // disabled={loading || !nameEntity}
                          onClick={handleStep2}
                        >
                          Siguiente
                        </Button>
                      </div>
                    </AvForm>
                  )}

                  //pantalla de software step ===  1
                  {step === 1 && (
                    <Row>
                      <Col md="12">
                        <h4 className="card-title">DATOS SOFTWARE</h4>
                        <hr />
                      </Col>
                      <Col md="12">
                        <br />
                        <br />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsSoftware.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {softwareListGrouped.map((group, rowIndex) => (
                                <>
                                  {/* Primera fila del grupo */}
                                  <tr key={`${rowIndex}-first`}>
                                    <td rowSpan={2}>{rowIndex + 1}</td>
                                    <td>{group[0].partNumber}</td>
                                    <td>{group[0].description}</td>

                                    <td className="w-100-px text-right">
                                      <Input
                                        type="number"
                                        value={group[0].quantityNumber}
                                        onChange={(e) =>
                                          handleQuantityChangeSoftware(
                                            rowIndex,
                                            0,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {formatCurrency(group[0].basePrice)}
                                    </td>
                                    <td className="w-100-px text-right">
                                      {formatCurrency(
                                        group[0].basePrice *
                                          group[0].quantityNumber
                                      )}
                                    </td>
                                  </tr>

                                  {/* Segunda fila del grupo */}
                                  {group[1] && (
                                    <tr key={`${rowIndex}-second`}>
                                      <td>{group[1].partNumber}</td>
                                      <td>{group[1].description}</td>
                                      <td className="w-100-px text-right">
                                        <Input
                                          type="number"
                                          value={group[1].quantityNumber}
                                          onChange={(e) =>
                                            handleQuantityChangeSoftware(
                                              rowIndex,
                                              1,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        {formatCurrency(group[1].basePrice)}
                                      </td>
                                      <td className="w-100-px text-right">
                                        {formatCurrency(
                                          group[1].basePrice *
                                            group[1].quantityNumber
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </tbody>

                            {/* Fila de totales */}
                            <tfoot>
                              <tr>
                                <td colSpan={3} className="text-center fw-bold">
                                  Total
                                </td>
                                <td className="text-center">
                                  {getTotalQuantitySW() +
                                    getTotalQuantitySMSA()}
                                </td>
                                <td></td>
                                <td>
                                  {formatCurrency(
                                    getSubtotalSW() + getSubtotalSMSA()
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3} className="text-center fw-bold">
                                  {" "}
                                  Subtotal Equipos SW
                                </td>
                                <td className="text-center">
                                  {getTotalQuantitySW()}
                                </td>
                                <td></td>
                                <td>{formatCurrency(getSubtotalSW())}</td>
                              </tr>
                              <tr>
                                <td colSpan={3} className="text-center fw-bold">
                                  Subtotal Equipos SMSA
                                </td>
                                <td className="text-center">
                                  {getTotalQuantitySMSA()}
                                </td>
                                <td></td>
                                <td>{formatCurrency(getSubtotalSMSA())}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </Col>

                      <Col md="12">
                        <br />
                        <br />
                        <div className="text-right">
                          <Button
                            className="btn btn-secondary-lexmark mr-3"
                            type="submit"
                            onClick={() => setStep(0)}
                          >
                            Volver
                          </Button>
                          <Button
                            className="btn btn-primary-lexmark box-shadow-none"
                            type="submit"
                            disabled={loading}
                            onClick={handlePreview}
                          >
                            Siguiente
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {step === 2 && (
                    <Row>
                      <Col md="12">
                        <h4 className="card-title text-center">
                          CONFIGURACIÓN LEXMARK
                        </h4>{" "}
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">Fecha: {getDateToday()}</p>
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          Nombre del reseller:{" "}
                          {profile ? profile.razonSocial : ""}
                        </p>
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          Datos Cliente: {nameEntity}{" "}
                        </p>{" "}
                        <br />
                        <br />
                      </Col>
                      <Col md="12">
                        <p className="card-title">
                          DESCRIPCIÓN DE LOS PRODUCTOS{" "}
                        </p>
                      </Col>

                      <Col md="12">
                        <hr />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsPreviewParent.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {itemsDescription.map((item, indexParent) => (
                                <React.Fragment key={item.index}>
                                  <tr>
                                    {columnsPreviewParent.map(
                                      (column, index) => {
                                        if (column.dataField === "indexGroup")
                                          return (
                                            <td
                                              className="text-center parent-row color-black"
                                              key={index}
                                              rowspan={
                                                item.components.length + 2
                                              }
                                            >
                                              {indexParent + 1}
                                            </td>
                                          );

                                        return (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumberWithouthDecimal &&
                                            item[column.dataField]
                                              ? (+item[
                                                  column.dataField
                                                ]).toLocaleString("en-US", {
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0,
                                                })
                                              : column.isNumber &&
                                                item[column.dataField]
                                              ? (+item[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : item[column.dataField]}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                  {item.components.map((child, indexChild) => (
                                    <tr key={child.index} className="child-row">
                                      {columnsPreviewParent
                                        .filter(
                                          (column) =>
                                            column.dataField !== "indexGroup"
                                        )
                                        .map((column, index) => (
                                          <td
                                            key={index}
                                            className={column.className}
                                          >
                                            {column.isNumberWithouthDecimal &&
                                            child[column.dataField]
                                              ? (+child[
                                                  column.dataField
                                                ]).toLocaleString("en-US", {
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0,
                                                })
                                              : column.isNumber &&
                                                child[column.dataField]
                                              ? (+child[column.dataField])
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(/\$/g, "$ ")
                                              : child[column.dataField]}
                                          </td>
                                        ))}
                                    </tr>
                                  ))}
                                  <tr key={`subtotal-indexParent`}>
                                    {columnsPreviewParent
                                      .filter(
                                        (column) =>
                                          column.dataField !== "indexGroup"
                                      )
                                      .map((column, index) => (
                                        <td
                                          key={index}
                                          className={`parent-row color-black ${column.className}`}
                                        >
                                          {index === 0 && "Subtotal"}

                                          {index ===
                                            columnsPreviewParent.length - 4 &&
                                            (+item.subTotal)
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}

                                          {index ===
                                            columnsPreviewParent.length - 2 &&
                                            (+item.subTotalContract)
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}
                                        </td>
                                      ))}
                                  </tr>
                                </React.Fragment>
                              ))}
                              <tr>
                                <td
                                  colSpan={columnsPreviewParent.length - 3}
                                  className="text-center"
                                >
                                  Total
                                </td>

                                <td className="text-right">
                                  {(+itemsDescription.reduce(
                                    (acc, item) => acc + +item.subTotal,
                                    0
                                  ))
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(/\$/g, "$ ")}
                                </td>
                                <td></td>
                                <td className="text-right">
                                  {(+itemsDescription.reduce(
                                    (acc, item) => acc + +item.subTotalContract,
                                    0
                                  ))
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(/\$/g, "$ ")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>

                      <Col md="12">
                        <br />
                        <br />
                        <p className="card-title">RESUMEN</p>
                      </Col>
                      <Col md="12">
                        <hr />
                        <div className="table-responsive">
                          <table className="custom-table">
                            <thead>
                              <tr>
                                {columnsPreview.map((column, index) => (
                                  <th className="text-center" key={index}>
                                    {column.text}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {itemsPreview.map((item, indexParent) => (
                                <React.Fragment key={item.index}>
                                  <tr key={indexParent}>
                                    <td
                                      className="text-center parent-row color-black"
                                      key={indexParent}
                                      rowspan={item.children.length + 2}
                                    >
                                      {indexParent + 1}
                                    </td>
                                    <td
                                      className="text-center bg-white color-black"
                                      key={indexParent}
                                      rowspan={item.children.length + 1}
                                    >
                                      {item.subgroup}
                                    </td>
                                  </tr>
                                  {item.children.map(
                                    (itemChild, indexChild) => (
                                      <tr key={indexParent}>
                                        {columnsPreview
                                          .filter(
                                            (col) =>
                                              col.dataField !== "indexGroup" &&
                                              col.dataField !== "subgroup"
                                          )
                                          .map((column, index) => {
                                            return (
                                              <td
                                                key={index}
                                                className={column.className}
                                              >
                                                {column.isNumberWithouthDecimal &&
                                                itemChild[column.dataField]
                                                  ? (+itemChild[
                                                      column.dataField
                                                    ]).toLocaleString("en-US", {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                    })
                                                  : column.isNumber &&
                                                    itemChild[column.dataField]
                                                  ? (+itemChild[
                                                      column.dataField
                                                    ])
                                                      .toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD",
                                                      })
                                                      .replace(/\$/g, "$ ")
                                                  : itemChild[column.dataField]}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                    )
                                  )}
                                  <tr key={`subtotal-indexParent`}>
                                    {columnsPreview
                                      .filter(
                                        (column) =>
                                          column.dataField !== "indexGroup"
                                      )
                                      .map((column, index) => (
                                        <td
                                          key={index}
                                          className={`parent-row color-black ${column.className}`}
                                        >
                                          {index === 0 && "Subtotal"}

                                          {index === columnsParent.length - 12 &&
                                            +item.children.reduce(
                                              (acc2, child) =>
                                                acc2 + +child.quantityNumber,
                                              0
                                            )}
                                          {index === columnsParent.length - 7 &&
                                            (+item.children.reduce(
                                              (acc2, child) =>
                                                acc2 + +child.totalPrice,
                                              0
                                            ))
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(/\$/g, "$ ")}
                                        </td>
                                      ))}
                                  </tr>
                                </React.Fragment>
                              ))}
                              <tr>
                                <td colSpan={6} className="text-center">
                                  Total
                                </td>

                                <td className="text-right">
                                  {
                                    +itemsPreview.reduce(
                                      (acc, item) =>
                                        acc +
                                        item.children.reduce(
                                          (acc2, child) =>
                                            acc2 + +child.quantityNumber,
                                          0
                                        ),
                                      0
                                    )
                                  }
                                </td>
                                <td></td>
                                <td className="text-right">
                                  {(+itemsPreview.reduce(
                                    (acc, item) =>
                                      acc +
                                      item.children.reduce(
                                        (acc2, child) =>
                                          acc2 + +child.totalPrice,
                                        0
                                      ),
                                    0
                                  ))
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(/\$/g, "$ ")}
                                </td>
                                <td></td>
                                <td className="text-right">
                                  {(+itemsPreview.reduce(
                                      (acc, item) =>
                                          acc +
                                          item.children.reduce(
                                              (acc2, child) =>
                                                  acc2 + +child.totalPriceContract,
                                              0
                                          ),
                                      0
                                  ))
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md="12">
                        <br />
                        <br />
                        <div className="text-right">
                          <Button
                            className="btn btn-secondary-lexmark mr-3"
                            type="submit"
                            disabled={loading}
                            onClick={() => setStep(1)}
                          >
                            Volver
                          </Button>
                          <Button
                            className="btn btn-primary-lexmark box-shadow-none"
                            type="submit"
                            disabled={loading || itemsPreview.length === 0}
                            onClick={handleSubmit}
                          >
                            Guardar
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <M show={show} onHide={handleClose}>
              <M.Header closeButton>
                <M.Title>REGISTRO EXITOSO </M.Title>
              </M.Header>
              <M.Body>
                Se ha registrado exitosamente la Configuración{" "}
                <span style={{ fontWeight: "bold" }}> {form.title} </span>.
              </M.Body>
              <M.Footer>
                <button
                  className="btn btn-primary-lexmark"
                  onClick={handleRedirectToListWhenTheConfiguratorIsCreated}
                >
                  CONTINUAR
                </button>
              </M.Footer>
            </M>
          </div>
        </Container>
      </div>

      <ModalCustom
        show={show}
        handleClose={handleClose}
        actionBtnLabel={"AGREGAR"}
        title="Agregar Configuración"
        customClass={"btn-primary-lexmark"}
        disabledSave={
          !itemSelected ||
          Object.keys(itemSelected).length === 0 ||
          (Object.keys(itemSelected).length > 0 &&
            itemSelected.components.filter((item) => item.selected).length ===
              0) ||
          (itemSelected && !itemSelected.monthVolume)
        }
        onClickEvent={() => {
          const updatedItems = [...items];
          const itemToAdd = { ...itemSelected };

          console.log(
            `Desde el modal Agregar Configuración updated ${JSON.stringify(
              updatedItems
            )}`
          );
          console.log(
            `Desde el modal Agregar Configuración item ${JSON.stringify(
              itemToAdd
            )}`
          );
          itemToAdd.components = itemToAdd.components
            .filter((child) => child.selected)
            .map((child, index) => ({
              ...child,
              quantityNumber: 1,
              quantityTotalContrato: child.subgroup === 'Opcionales' ? 1 : +itemToAdd.months,
              totalPrice: +child.priceLocalUS,
              totalPriceContract: +child.priceLocalUS *
                  +(child.subgroup === 'Opcionales' ? 1 : +itemToAdd.months),
            }));

          if (itemToAdd.monthVolume) {
            itemToAdd.components.forEach((child) => {
              if (child.performance) {
                let monthVolume = +itemToAdd.monthVolume;
                let monthVolumeColor = +itemToAdd.monthVolumeColor;
                let totalVolumenBlack = monthVolume * itemToAdd.quantityNumber;
                let totalVolumeColor =
                  monthVolumeColor * itemToAdd.quantityNumber;
                let totalVolume = totalVolumeColor + totalVolumenBlack;
                let performance = +child.performance;
                let appliesPerformanceProvision = itemToAdd.appliesProvision;
                let performanceProvision =
                  isNaN(child.performance) ||
                  isNaN(itemToAdd.provisionPercentage)
                    ? 0
                    : Math.abs(
                        Math.round(
                          (Number(child.performance) || 0) *
                            (Number(itemToAdd.provisionPercentage) / 100 - 1)
                        )
                      );

                child.performanceProvision = performanceProvision;

                if (child.volumen === "black") {
                  if (itemToAdd.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolumenBlack / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(
                      totalVolumenBlack / performance
                    );
                  }
                } else if (child.volumen === "color") {
                  if (itemToAdd.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolumeColor / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(
                      totalVolumeColor / performance
                    );
                  }
                } else if (child.volumen === "total") {
                  if (itemToAdd.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolume / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(totalVolume / performance);
                  }
                }

                if (child.subgroup === 'Opcionales') {
                  child.quantityTotalContrato = +(child.quantityNumber || 1);
                } else {
                  child.quantityTotalContrato = +(child.quantityNumber || 1) * +itemToAdd.months;
                }

                child.totalPrice =
                  +(child.quantityNumber || 1) * +child.priceLocalUS;

                child.totalPriceContract =
                  +child.priceLocalUS *
                    +child.quantityTotalContrato;
              }
            });
          }

          itemToAdd.quantityTotalContrato = itemToAdd.quantityNumber;
          itemToAdd.totalPrice = itemToAdd.priceLocalUS;
          itemToAdd.totalPriceContract =
            itemToAdd.priceLocalUS * itemToAdd.quantityTotalContrato;

          itemToAdd.subTotal = (
            itemToAdd.components.reduce(
              (acc, item) => acc + +item.totalPrice,
              0
            ) + +itemToAdd.totalPrice
          ).toFixed(2);

          itemToAdd.subTotalContract = (
            itemToAdd.components.reduce(
              (acc, item) => acc + +item.totalPriceContract,
              0
            ) + +itemToAdd.totalPriceContract
          ).toFixed(2);

          updatedItems.push(itemToAdd);
          const updateItemsSet = updatedItems.map((item) => ({
            ...item, // Mantén las propiedades del item actual
            totalPrice: item.priceLocalUS * item.quantityNumber, // Calcula el nuevo totalPrice basado en la cantidad
            totalPriceContract:
              item.priceLocalUS * item.quantityTotalContrato, // Calcula el nuevo totalPrice basado en la cantidad
            subTotal: item.components.reduce(
              (acc, child) => acc + +child.totalPrice,
              0 // Suma los totalPrice de los componentes ya actualizados
            ),
            subTotalContract: item.components.reduce(
              (acc, child) => acc + +child.totalPriceContract,
              0 // Suma los totalPrice de los componentes ya actualizados
            ),
          }));

          const updateItemsSetSubtotal = updateItemsSet.map((item) => ({
            ...item, // Mantén las propiedades del item actual
            subTotal: item.subTotal + item.totalPrice,
            subTotalContract: item.subTotalContract + item.totalPriceContract,
          }));

          //console.log(updateItemsSetSubtotal);
          setItems(updateItemsSetSubtotal);

          //console.log(updatedItems);
          //console.log(itemToAdd);
          handleClose();
        }}
      >
        <div className="modal-content-deny-opportunity">
          <div>
            <Label className="form-label" htmlFor="configurator-add-item">
              Buscar impresora
            </Label>
            <div className="mb-3 d-flex">
              <Col md={12} style={{ width: "100%" }}>
                <div className="search-printer">
                  <Input
                    type="text"
                    value={searchPrinter}
                    onChange={(e) => setSearchPrinter(e.target.value)}
                    placeholder="29S0050,MS431dn,42/40 ppm, 256MB, 1 GHz, 2-line APA display, DC 80K"
                  />

                  <Button
                    className="btn btn-primary-lexmark box-shadow-none"
                    type="button"
                    onClick={getPrinters}
                    disabled={searchPrinter.length === 0}
                  >
                    <i className="fa fa-search"></i>
                  </Button>
                </div>
                <hr />
                {Object.keys(itemSelected).length === 0 &&
                  printers.length > 0 && (
                    <div className="printers-container">
                      {printers.map((data, index) => (
                        <div
                          className="printer-item printer-item-list"
                          key={index}
                          onClick={() => {
                            setItemSelected({
                              ...data,
                              monthVolumeColor: null,
                              monthVolume: null,
                            });
                          }}
                        >
                          {console.log(data)}
                          <p>
                            <b>Número de Parte:</b> {data.partNumber} <br />
                            <b>Modelo:</b> {data.model}
                          </p>

                          <p>
                            <b>Descripción:</b> {data.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                {Object.keys(itemSelected).length > 0 && (
                  <div className="printer-item">
                    <p>
                      <b>Número de Parte:</b> {itemSelected.partNumber} <br />
                      <b>Modelo:</b> {itemSelected.model}
                    </p>
                    <p>
                      <b>Descripción:</b> {itemSelected.description}
                    </p>
                    <div
                      className="remove-printer"
                      onClick={() => setItemSelected({})}
                    >
                      {" "}
                      X{" "}
                    </div>
                  </div>
                )}
              </Col>
            </div>
          </div>

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Cantidad de impresora(s)
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected.quantityNumber ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        quantityNumber:
                          value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>
            </div>
          )}

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Ingresar volumen B/N mensual por impresora
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected.monthVolume ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        monthVolume: value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>
            </div>
          )}

          {!!itemSelected &&
            Object.keys(itemSelected).length > 0 &&
            itemSelected.type.toLowerCase().includes("color") && (
              <div>
                <Label className="form-label" htmlFor="configurator-add-item">
                  Ingresar volumen de color mensual por impresora
                </Label>
                <div className="mb-3 d-flex">
                  <Col md={12} style={{ width: "100%" }}>
                    <Input
                      type="number"
                      min={1}
                      value={itemSelected.monthVolumeColor ?? ""}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        setItemSelected({
                          ...itemSelected,
                          monthVolumeColor:
                            value === "" ? null : Math.max(1, +value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </div>
            )}

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              {/* Cantidad de meses */}
              <Label className="form-label" htmlFor="configurator-add-item">
                Cantidad de meses del contrato
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected?.months ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        months: value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>

              {/* Aplica provisión */}
              <Label className="form-label">Aplica provisión</Label>
              <div className="mb-3 d-flex">
                <Col
                  md={12}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span>Activar si necesita provisión:</span>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="appliesProvisionSwitch"
                      checked={!!itemSelected?.appliesProvision}
                      onChange={(e) => {
                        const appliesProvision = e.target.checked;
                        setItemSelected({
                          ...itemSelected,
                          appliesProvision,
                        });
                      }}
                      style={{ width: "2.5rem", height: "1.5rem" }} // Ajuste del tamaño del switch
                    />
                  </div>
                </Col>
              </div>

              {/* Porcentaje (se muestra solo si Aplica provisión está activado) */}
              {itemSelected?.appliesProvision && (
                <>
                  <Label className="form-label">Porcentaje</Label>
                  <div className="mb-3 d-flex">
                    <Col md={12} style={{ width: "100%" }}>
                      <Input
                        type="select"
                        value={itemSelected?.provisionPercentage ?? ""}
                        onChange={(e) => {
                          const value =
                            e.target.value === "" ? null : +e.target.value;
                          setItemSelected({
                            ...itemSelected,
                            provisionPercentage: value,
                          });
                        }}
                      >
                        <option value="" disabled>
                          Seleccione porcentaje de provisión
                        </option>
                        <option value={5}>5%</option>
                        <option value={10}>10%</option>
                        <option value={15}>15%</option>
                        <option value={20}>20%</option>
                      </Input>
                    </Col>
                  </div>
                </>
              )}
            </div>
          )}

          <div>
            <div className="table-responsive">
              {!!itemSelected && Object.keys(itemSelected).length > 0 && (
                
                <table className="custom-table">
                  {console.log(itemSelected)}
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th className="text-center" key={index}>
                          {column.text}
                        </th>
                      ))}
                      <th>Agregar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemSelected?.components
                      ?.sort((a, b) => {
                        // Ordenamos por 'subgroup'
                        if (a.subgroup < b.subgroup) return -1;
                        if (a.subgroup > b.subgroup) return 1;
                        return 0;
                      })
                      .map((item) => (
                        <React.Fragment key={item.index}>
                          <tr className="child-row">
                            {columns.map((column, index) => (
                              <td key={index}>
                                {column.isNumberWithouthDecimal &&
                                item[column.dataField]
                                  ? (+item[column.dataField]).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )
                                  : column.isNumber && item[column.dataField]
                                  ? (+item[column.dataField])
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")
                                  : item[column.dataField]}
                              </td>
                            ))}
                            <td className="text-center">
                              <Input
                                type="checkbox"
                                checked={item.selected || false}
                                className="form-check-input"
                                onChange={() =>
                                  handleSelectSupplies(item.partNumber)
                                }
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </ModalCustom>

      <ModalCustom
        show={showEdit}
        handleClose={handleCloseEdit}
        actionBtnLabel={"EDITAR"} // Cambiar el texto del botón
        title="Editar Configuración" // Cambiar título
        customClass={"btn-primary-lexmark-2"}
        disabledSave={
          !itemSelected ||
          Object.keys(itemSelected).length === 0 ||
          itemSelected.components.filter((item) => item.selected).length ===
            0 ||
          !itemSelected.monthVolume
        }
        onClickEvent={() => {
          const updatedItems = [...items];
          const itemToUpdate = { ...itemSelected };

          console.log(`UpdatedItem ${updatedItems}`);
          console.log(`ItemToUpdate ${itemToUpdate}`);

          // Lógica para filtrar y actualizar componentes seleccionados
          itemToUpdate.components = itemToUpdate.components
              .filter((child) => child.selected)
              .map((child) => {
                const quantityNumber = +child.quantityNumber || 1; // Default to 1 if undefined
                const priceLocalUS = +child.priceLocalUS || 0; // Default to 0 if undefined
                const months = +itemToUpdate.months || 0; // Default to 0 if undefined
                const subgroupMultiplier =
                    child.subgroup === 'Opcionales' ? quantityNumber : months;

                return {
                  ...child,
                  quantityNumber,
                  quantityTotalContrato: subgroupMultiplier,
                  totalPrice: quantityNumber * priceLocalUS,
                  totalPriceContract: priceLocalUS * subgroupMultiplier,
                };
              });


          // Lógica para actualizar el totalPrice y subTotal
          if (itemToUpdate.monthVolume) {
            itemToUpdate.components.forEach((child) => {
              if (child.performance) {
                let monthVolume = +itemToUpdate.monthVolume;
                let monthVolumeColor = +itemToUpdate.monthVolumeColor;
                let totalVolumenBlack =
                  monthVolume * itemToUpdate.quantityNumber;
                let totalVolumeColor =
                  monthVolumeColor * itemToUpdate.quantityNumber;
                let totalVolume = totalVolumeColor + totalVolumenBlack;
                let performance = +child.performance;
                let performanceProvision =
                  isNaN(child.performance) ||
                  isNaN(itemToUpdate.provisionPercentage)
                    ? 0
                    : Math.abs(
                        Math.round(
                          (Number(child.performance) || 0) *
                            (Number(itemToUpdate.provisionPercentage) / 100 - 1)
                        )
                      );

                child.performanceProvision = performanceProvision;
                if (child.volumen === "black") {
                  if (itemToUpdate.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolumenBlack / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(
                      totalVolumenBlack / performance
                    );
                  }
                } else if (child.volumen === "color") {
                  if (itemToUpdate.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolumeColor / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(
                      totalVolumeColor / performance
                    );
                  }
                } else if (child.volumen === "total") {
                  if (itemToUpdate.appliesProvision) {
                    child.quantityNumber = Math.ceil(
                      totalVolume / performanceProvision
                    );
                  } else {
                    child.quantityNumber = Math.ceil(totalVolume / performance);
                  }
                }
                if (child.subgroup === 'Opcionales') {
                  child.quantityTotalContrato = +(child.quantityNumber || 1);
                } else {
                  child.quantityTotalContrato = +(child.quantityNumber || 1) * +itemToUpdate.months;
                }

                child.totalPrice =
                  +(child.quantityNumber || 1) * +child.priceLocalUS;

                child.totalPriceContract =
                  +child.priceLocalUS * child.quantityTotalContrato;
              }
            });
          }

          itemToUpdate.quantityTotalContrato = itemToUpdate.quantityNumber;
          itemToUpdate.totalPrice =
            itemToUpdate.priceLocalUS * itemToUpdate.quantityNumber;
          itemToUpdate.totalPriceContract =
            itemToUpdate.priceLocalUS * itemToUpdate.quantityTotalContrato;

          itemToUpdate.subTotalContract = (
            itemToUpdate.components.reduce(
              (acc, item) => acc + +item.totalPriceContract,
              0
            ) + +itemToUpdate.totalPriceContract
          ).toFixed(2);

          itemToUpdate.subTotal = (
            itemToUpdate.components.reduce(
              (acc, item) => acc + +item.totalPrice,
              0
            ) + +itemToUpdate.totalPrice
          ).toFixed(2);

          // Actualizar el array de items
          const index = updatedItems.findIndex(
            (item) => item.id === itemToUpdate.id
          );
          if (index !== -1) {
            updatedItems[index] = itemToUpdate; // Actualizar el item en la posición correcta
          }

          console.log(updatedItems)
          console.log(itemToUpdate)
          setItems(updatedItems);
          handleCloseEdit(); // Cerrar el modal
        }}
      >
        <div className="modal-content-deny-opportunity">
          <div>
            <Label className="form-label" htmlFor="configurator-add-item">
              Buscar impresora
            </Label>
            <div className="mb-3 d-flex">
              <Col md={12} style={{ width: "100%" }}>
                <div className="search-printer">
                  <Input
                    type="text"
                    value={searchPrinter}
                    onChange={(e) => setSearchPrinter(e.target.value)}
                    placeholder="29S0050,MS431dn,42/40 ppm, 256MB, 1 GHz, 2-line APA display, DC 80K"
                  />
                  <Button
                    className="btn btn-primary-lexmark box-shadow-none"
                    type="button"
                    onClick={getPrinters}
                    disabled={searchPrinter.length === 0}
                  >
                    <i className="fa fa-search"></i>
                  </Button>
                </div>
                <hr />
                {Object.keys(itemSelected).length === 0 &&
                  printers.length > 0 && (
                    <div className="printers-container">
                      {printers.map((data, index) => (
                        <div
                          className="printer-item printer-item-list"
                          key={index}
                          onClick={() => {
                            setItemSelected({
                              ...data,
                              monthVolumeColor: null,
                              monthVolume: null,
                            });
                          }}
                        >
                          <p>
                            <b>Número de Parte:</b> {data.partNumber} <br />
                            <b>Modelo:</b> {data.model}
                          </p>
                          <p>
                            <b>Descripción:</b> {data.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                {Object.keys(itemSelected).length > 0 && (
                  <div className="printer-item">
                    <p>
                      <b>Número de Parte:</b> {itemSelected.partNumber} <br />
                      <b>Modelo:</b> {itemSelected.model}
                    </p>
                    <p>
                      <b>Descripción:</b> {itemSelected.description}
                    </p>
                    <div
                      className="remove-printer"
                      onClick={() => setItemSelected({})}
                    >
                      X
                    </div>
                  </div>
                )}
              </Col>
            </div>
          </div>


          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Cantidad de impresora(s)
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected.quantityNumber ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        quantityNumber:
                          value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>
            </div>
          )}

{!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              <Label className="form-label" htmlFor="configurator-add-item">
                Ingresar volumen B/N mensual por impresora
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected.monthVolume ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        monthVolume: value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>
            </div>
          )}

          {!!itemSelected &&
            Object.keys(itemSelected).length > 0 &&
            itemSelected.type.toLowerCase().includes("color") && (
              <div>
                <Label className="form-label" htmlFor="configurator-add-item">
                  Ingresar volumen de color mensual por impresora
                </Label>
                <div className="mb-3 d-flex">
                  <Col md={12} style={{ width: "100%" }}>
                    <Input
                      type="number"
                      min={1}
                      value={itemSelected.monthVolumeColor ?? ""}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        setItemSelected({
                          ...itemSelected,
                          monthVolumeColor:
                            value === "" ? null : Math.max(1, +value),
                        });
                      }}
                    />
                  </Col>
                </div>
              </div>
            )}

          {!!itemSelected && Object.keys(itemSelected).length > 0 && (
            <div>
              {/* Cantidad de meses */}
              <Label className="form-label" htmlFor="configurator-add-item">
                Cantidad de meses del contrato
              </Label>
              <div className="mb-3 d-flex">
                <Col md={12} style={{ width: "100%" }}>
                  <Input
                    type="number"
                    min={1}
                    value={itemSelected?.months ?? ""}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setItemSelected({
                        ...itemSelected,
                        months: value === "" ? null : Math.max(1, +value),
                      });
                    }}
                  />
                </Col>
              </div>

              {/* Aplica provisión */}
              <Label className="form-label">Aplica provisión</Label>
              <div className="mb-3 d-flex">
                <Col
                  md={12}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span>Activar si necesita provisión:</span>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="appliesProvisionSwitch"
                      checked={!!itemSelected?.appliesProvision}
                      onChange={(e) => {
                        const appliesProvision = e.target.checked;
                        setItemSelected({
                          ...itemSelected,
                          appliesProvision,
                        });
                      }}
                      style={{ width: "2.5rem", height: "1.5rem" }} // Ajuste del tamaño del switch
                    />
                  </div>
                </Col>
              </div>

              {/* Porcentaje (se muestra solo si Aplica provisión está activado) */}
              {itemSelected?.appliesProvision && (
                <>
                  <Label className="form-label">Porcentaje</Label>
                  <div className="mb-3 d-flex">
                    <Col md={12} style={{ width: "100%" }}>
                      <Input
                        type="select"
                        value={itemSelected?.provisionPercentage ?? ""}
                        onChange={(e) => {
                          const value =
                            e.target.value === "" ? null : +e.target.value;
                          setItemSelected({
                            ...itemSelected,
                            provisionPercentage: value,
                          });
                        }}
                      >
                        <option value="" disabled>
                          Seleccione porcentaje de provisión
                        </option>
                        <option value={5}>5%</option>
                        <option value={10}>10%</option>
                        <option value={15}>15%</option>
                        <option value={20}>20%</option>
                      </Input>
                    </Col>
                  </div>
                </>
              )}
            </div>
          )}

          <div>
            <div className="table-responsive">
              {!!itemSelected && Object.keys(itemSelected).length > 0 && (
                <table className="custom-table">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th className="text-center" key={index}>
                          {column.text}
                        </th>
                      ))}
                      <th>Agregar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemSelected?.components
                      ?.sort((a, b) => {
                        // Ordenamos por 'subgroup'
                        if (a.subgroup < b.subgroup) return -1;
                        if (a.subgroup > b.subgroup) return 1;
                        return 0;
                      })
                      .map((item) => (
                        <React.Fragment key={item.index}>
                          <tr className="child-row">
                            {columns.map((column, index) => (
                              <td key={index}>
                                {column.isNumberWithouthDecimal &&
                                item[column.dataField]
                                  ? (+item[column.dataField]).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )
                                  : column.isNumber && item[column.dataField]
                                  ? (+item[column.dataField])
                                      .toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                      .replace(/\$/g, "$ ")
                                  : item[column.dataField]}
                              </td>
                            ))}
                            <td className="text-center">
                              <Input
                                type="checkbox"
                                checked={item.selected || false}
                                className="form-check-input"
                                onChange={() =>
                                  handleSelectSupplies(item.partNumber)
                                }
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </ModalCustom>
    </React.Fragment>
  );
};

export default ConfiguratorRegister;
