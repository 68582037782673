import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Container } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

// i18n
import { withNamespaces } from "react-i18next";

import { useSelector } from "react-redux";

const NavbarChannel = ({ location, t }) => {
  const [appState, setAppState] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location.pathname]);

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  const menuOpen = useSelector((state) => state.Layout.menuOpen);
  const profile = useSelector((state) => state.profileReducer.profile);

  return (
    <React.Fragment>
      <div className="topnav">
        <Container fluid>
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={menuOpen}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setAppState(!appState);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    id="topnav-canales"
                    role="button"
                  >
                    <i className="ri-apps-2-line me-2"></i>{" "}
                    {t("Solicitar Certificados")}{" "}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: appState,
                    })}
                    aria-labelledby="topnav-canales"
                  >
                    <Link to="/create-supply-request" className="dropdown-item">
                      {t("Suministros")}
                    </Link>
                    <Link to="/create-team-request" className=" dropdown-item">
                      {t("Equipos")}
                    </Link>
                    <Link
                      to="/create-special-request"
                      className="dropdown-item"
                    >
                      {t("Especiales")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/consult-request-channel"
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-uielement"
                  >
                    {t("Consultar Solicitudes")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setAppState(!appState);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    id="topnav-apps"
                    role="button"
                  >
                    <i className="ri-apps-2-line me-2"></i>
                    {t("Oportunidad")} <div className="arrow-down"></div>
                  </Link>

                  <div
                    className={classname("dropdown-menu dropdown-menu-end", {
                      show: appState,
                    })}
                    aria-labelledby="topnav-canales"
                  >
                    <Link to="/create-opportunity" className="dropdown-item">
                      {t("Crear Oportunidad")}
                    </Link>
                    <Link to="/opportunity-list" className="dropdown-item">
                      {t("Lista Oportunidades")}
                    </Link>
                  </div>
                </li>

                {profile && profile.tipo === "CONNECT" && (
                  <li className="nav-item dropdown">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setAppState(!appState);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      id="topnav-apps"
                      role="button"
                    >
                      <i className="ri-apps-2-line me-2"></i>
                      {t("Configurador")} <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu dropdown-menu-end", {
                        show: appState,
                      })}
                      aria-labelledby="topnav-canales"
                    >
                      <Link to="/create-configurator" className="dropdown-item">
                        {t("Crear Configuración")}
                      </Link>
                      <Link to="/configurator-list" className="dropdown-item">
                        {t("Lista Configuraciones")}
                      </Link>
  
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(NavbarChannel));
