import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import DatePicker from "react-flatpickr";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "flatpickr/dist/themes/material_green.css";
import { toast } from "react-toastify";
import useHttp from "../../hook/use-http";
import { format } from "date-fns";

export default function CatalogoElectronicoEstadisticas({ history }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [totalTimeSpentMinutes, setTotalTimeSpentMinutes] = useState(0);
  const [totalTimeSpentSeconds, setTotalTimeSpentSeconds] = useState(0);
  const [totalVistas, setTotalVistas] = useState(0);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { request } = useHttp();

  useEffect(() => {
    if (startDate && endDate) {
      getDataDates();
    }
  }, [startDate, endDate]);

  const getDataDates = () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      request(
        {
          url: `/auth/util/list/date?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
          method: "GET",
          headers: myHeaders,
        },
        handleGetDataResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDataResponse = (data) => {
    if (data.status != null) {
      if (data.status === 401) {
        // show a error message here

        if (data.errorCode === 12) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("email");
          localStorage.removeItem("role");
          history.push("/login");
        }

        toast(data.message, { type: "error", autoClose: 10000 });
      }
    }

    const groupedData = data.reduce((acc, curr) => {
      const date = format(
        new Date(curr.createdDate.seconds * 1000),
        "yyyy-MM-dd"
      );
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const chartDataFormatted = Object.keys(groupedData).map((date) => ({
      date,
      visitantes: groupedData[date],
    }));

    //Tiempo en pantalla
    const totalTimeInSeconds = data.reduce(
      (sum, item) => sum + item.timeSpent,
      0
    );

    // Convertir el total de segundos en minutos y segundos
    const totalMinutes = Math.floor(totalTimeInSeconds / 60);
    const remainingSeconds = Math.round(totalTimeInSeconds % 60);

    setTotalTimeSpentMinutes(totalMinutes);
    setTotalTimeSpentSeconds(remainingSeconds);

    setChartData(chartDataFormatted);
    setData(data);
    setTotalVisitors(
      chartDataFormatted.reduce((sum, item) => sum + item.visitantes, 0)
    );

    setTotalVistas(data.reduce((sum, item) => sum + item.visit, 0));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <label>Fecha de Inicio</label>
                      <DatePicker
                        className="form-control"
                        options={{ dateFormat: "d/m/y", maxDate: new Date() }}
                        value={startDate}
                        onChange={([date]) => setStartDate(date)}
                        placeholder="Selecciona una fecha de inicio"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Fecha de Fin</label>
                      <DatePicker
                        className="form-control"
                        options={{
                          dateFormat: "d/m/y",
                          minDate: startDate || "today",
                          maxDate: startDate
                            ? new Date(
                                startDate.getTime() + 30 * 24 * 60 * 60 * 1000
                              )
                            : null,
                        }}
                        disabled={!startDate}
                        value={endDate}
                        onChange={([date]) => setEndDate(date)}
                        placeholder="Selecciona una fecha final"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {startDate && endDate && chartData.length > 0 && (
                <>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Visitantes por Día</CardTitle>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={chartData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis allowDecimals={false} />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="visitantes"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Resumen</CardTitle>

                      <div className="mb-3">
                        <CardText className="text-success font-weight-bold">
                          Total Visitantes
                        </CardText>
                        <CardText className="lead text-muted">
                          {totalVisitors}
                        </CardText>
                      </div>

                      <div className="mb-3">
                        <CardText className="text-success font-weight-bold">
                          Total Vistas
                        </CardText>
                        <CardText className="lead text-muted">
                          {totalVistas}
                        </CardText>
                      </div>
                      <div className="mb-3">
                        <CardText className="text-success font-weight-bold">
                          Total de tiempo en pantalla:
                        </CardText>
                        <CardText className="lead text-muted">
                          {totalTimeSpentMinutes} minutos y{" "}
                          {totalTimeSpentSeconds} segundos
                        </CardText>
                      </div>
                    </CardBody>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
