import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useHttp from "../../hook/use-http";
import Spreadsheet from "react-spreadsheet";

// Initialize Toast
toast.configure();

const ConfiguratorItemsRegister = ({ history }) => {
  const [messageRequest, setMessageRequest] = useState("");
  const [messageStatusIsError, setMessageStatusIsError] = useState(null);
  const { loading, error, request } = useHttp();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const printers = ["SFP", "MFP", "MPF A3", "MFP A3"];
  const columnLabels = [
    "NUMERO DE PARTE",
    "GRUPO",
    "TIPO",
    "MODELO",
    "DESCRIPCION",
    "RENDIMIENTO",
    "PRECIO LOCAL US$ CANAL",
    "SUBGRUPO",
  ];
  const [form, setForm] = useState({ descripcion: null });

  const handleCreateBannerDataResponse = (response) => {
    if (response.status && response.errorCode || response.status >= 400) {
      if (response.errorCode === 12) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(response.message);
      toast.error(response.message, { autoClose: 5000 });
      setMessageRequest(response.message);
      setMessageStatusIsError(true);
    } else {
      toast.success("Datos registrados correctamente.", { autoClose: 3000 });
      history.push(`/configurator-items-created`);
    }
  };

  const handleSubmit = async (error, values) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
      myHeaders.append("Content-Type", "application/json");

      await request(
          {
            url: `/auth/v1/configurador/suministros/register-all`,
            method: "POST",
            headers: myHeaders,
            body: {
              parent: { descripcion: form.descripcion },
              configurationSupplies: dataOriginal,
            },
          },
          handleCreateBannerDataResponse
      );
    } catch (error) {
      console.error("Error submitting form:", error);

      let errorMessage = "Error al enviar los datos. Inténtelo de nuevo.";

      if (error.response) {
        try {
          const errorData = await error.response.json();
          if (errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (jsonError) {
          console.error("Error parsing JSON response:", jsonError);
        }
      }

      toast.error(errorMessage, { autoClose: 5000 });
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      let lastParentNumeroParte = null; // Keeps track of the last matching numero_de_parte

      jsonData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          let fixed_key = key.toLowerCase().trim().replace(/ /g, "_").replace("$", "");
          if (!isNaN(item[key])) {
            item[key] = (+item[key]).toFixed(2);
          }
          item[fixed_key] = item[key] + "";
          delete item[key];
        });

        // Check if the item's "grupo" is in the printers array
        if (printers.includes(item.grupo)) {
          lastParentNumeroParte = item.numero_de_parte;
          item.numero_de_parte_padre = null;
        } else {
          item.numero_de_parte_padre = lastParentNumeroParte;
          if (item.tipo === "Mono Laser") {
            if (item.rendimiento && item.grupo.substring(0, 8) === "Supplies") {
              item.volumen = "black";
            } else {
              item.volumen = "total";
            }
          } else if (item.tipo === "Color Laser") {
            const lastTwoChart = item.numero_de_parte.slice(-2);
            const color = ["C0", "M0", "Y0"];
            const black = ["K0"];
            if (
                item.rendimiento &&
                color.includes(lastTwoChart) &&
                item.grupo.substring(0, 8) === "Supplies"
            ) {
              item.volumen = "color";
            } else if (
                item.rendimiento &&
                black.includes(lastTwoChart) &&
                item.grupo.substring(0, 8) === "Supplies"
            ) {
              item.volumen = "black";
            } else {
              item.volumen = "total";
            }
          }
        }

      });

      let auxData = jsonData.map((item) => [
        { value: item.numero_de_parte, readOnly: true },
        { value: item.grupo, readOnly: true },
        { value: item.tipo, readOnly: true },
        { value: item.modelo, readOnly: true },
        { value: item.descripcion, readOnly: true },
        { value: item.rendimiento, readOnly: true },
        { value: item.precio_local_us_canal, readOnly: true },
        { value: item.subgrupo, readOnly: true },
      ]);

      let dataOriginalAux = jsonData.map((item) => ({
        numeroParte: item.numero_de_parte || "",
        grupo: item.grupo || "",
        tipo: item.tipo || "",
        modelo: item.modelo || "",
        descripcion: item.descripcion || "",
        rendimiento: item.rendimiento || "",
        impresora: item.numero_de_parte_padre ? "false" : "true",
        precioReferencialUnitario: item.precio_local_us_canal?.replace(",", "") || 0,
        numeroPartePadre: item.numero_de_parte_padre || "",
        volumen: item.volumen || "",
        subgrupo: item.subgrupo || "",
      }));

      setDataOriginal(dataOriginalAux);
      setData(auxData);
    };

    reader.readAsBinaryString(file);
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                      <h4 className="card-title">CARGAR LISTA COMPLETA DEL CONFIGURADOR</h4>
                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label htmlFor="banner-create-note">Descripción</Label>
                            <AvField
                                name="descripcion"
                                type="text"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                value={form.descripcion}
                                onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label htmlFor="item-configurator-upload">Cargar Excel</Label>
                            <AvField
                                accept=".xlsx, .xls"
                                name="excel"
                                type="file"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                onChange={handleFileUpload}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <h4 className="card-title">PREVISUALIZAR LOS DATOS</h4>
                        </Col>
                        <Col md="12">
                          <hr />
                          <p>
                            <b>Total impresoras:</b> {dataOriginal.filter(item => !item.numeroPartePadre).length}
                          </p>
                          <div className="spreadsheet-container">
                            <Spreadsheet data={data} columnLabels={columnLabels} />
                          </div>
                        </Col>
                      </Row>

                      <div className="text-right mt-3">
                        <Button
                            className="create-button btn btn-primary-lexmark"
                            type="submit"
                            disabled={dataOriginal.length === 0 || !form.descripcion}
                        >
                          Crear
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* CSS Fix */}
        <style>{`
        .spreadsheet-container {
          max-height: 400px;
          overflow: auto;
          position: relative;
          z-index: 1;
        }
        .create-button {
          position: relative;
          z-index: 10;
          pointer-events: auto !important;
        }
      `}</style>
      </React.Fragment>
  );
};

export default ConfiguratorItemsRegister;